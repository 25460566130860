import { type ArgumentArray, default as classnames } from 'classnames';
import { extendTailwindMerge, validators } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [{ text: ['mono-sm', 'mono-md', validators.isTshirtSize, validators.isArbitraryLength] }],
    },
  },
});

export function cn(...inputs: ArgumentArray) {
  return twMerge(classnames(inputs));
}
