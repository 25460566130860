// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gJGdW964oXjPCECq0KFV {\n  margin-bottom: 16px;\n}\n\n.form-section .gJGdW964oXjPCECq0KFV:last-child {\n  margin-bottom: 0;\n}\n\n.xg48kGzFomZH5F1I7hBh {\n  display: block;\n  line-height: 1;\n  margin-bottom: 8px;\n}\n\n.MfYryjEeCSKPa0ekFxDL {\n  font-size: 10px;\n  font-weight: 400;\n  text-transform: uppercase;\n  color: var(--axi-form-item-label-color);\n}\n\n.azKilAqf4jOf1H8g1efw {\n  font-size: 10px;\n  font-weight: 500;\n  text-transform: uppercase;\n  color: var(--axi-body-color);\n}\n\n.A5lynjNCrHmoYV23xWPb > :not([role=switch]):not(label) {\n  width: 100%;\n}\n\n.DecHebKX95mNef8VxGtg,\n.Ss6CEfFOcF0bHikTJ1uA {\n  color: var(--axi-form-item-helper-color);\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 24px;\n  margin-bottom: 0;\n  vertical-align: bottom;\n}\n.DecHebKX95mNef8VxGtg p:last-child,\n.Ss6CEfFOcF0bHikTJ1uA p:last-child {\n  margin-bottom: 0;\n}\n\n.pmvpnUz2Hy97KC3ws5fO .Ss6CEfFOcF0bHikTJ1uA {\n  color: var(--axi-form-item-helper-color-error);\n}\n\n.fGjm8a9GGNxctGNqcfnI {\n  display: flex;\n}\n.fGjm8a9GGNxctGNqcfnI .xg48kGzFomZH5F1I7hBh {\n  flex: 0 0 auto;\n  line-height: 1.3;\n  padding-top: 9px;\n  padding-right: 0.75em;\n  text-align: right;\n  width: 160px;\n  transform: translateY(-4px);\n}\n.fGjm8a9GGNxctGNqcfnI .A5lynjNCrHmoYV23xWPb {\n  flex: 1 1 auto;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "gJGdW964oXjPCECq0KFV",
	"label": "xg48kGzFomZH5F1I7hBh",
	"label-default": "MfYryjEeCSKPa0ekFxDL",
	"labelDefault": "MfYryjEeCSKPa0ekFxDL",
	"label-strong": "azKilAqf4jOf1H8g1efw",
	"labelStrong": "azKilAqf4jOf1H8g1efw",
	"item": "A5lynjNCrHmoYV23xWPb",
	"description": "DecHebKX95mNef8VxGtg",
	"helper": "Ss6CEfFOcF0bHikTJ1uA",
	"error": "pmvpnUz2Hy97KC3ws5fO",
	"horizontal": "fGjm8a9GGNxctGNqcfnI"
};
export default ___CSS_LOADER_EXPORT___;
