

module.exports = function loaderko(locale, cb) {
  require.ensure([
      'moment/locale/ko'
      , 'numbro/dist/languages/ko-KR.min'
      , 'sugar-date/locales/ko'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/ko');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const numbro = require('numbro');
      numbro.registerLanguage(require('numbro/dist/languages/ko-KR.min'));
      numbro.setLanguage(locale);

    const Sugar = require('sugar-date');
      require('sugar-date/locales/ko');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-ko');
};

