export type ENV = 'dev' | 'staging' | 'prod' | 'eu';

export const isEnvProd = () => {
  return window.location.host.includes('app.axiom.co');
};

const DEV_ENVS = ['localhost', 'app.dev.axiomtestlabs.co', '.axiom.dev'];
export const isEnvDev = () => {
  return DEV_ENVS.some((env) => window.location.host.includes(env));
};

export const isEnvStaging = () => {
  return window.location.host.includes('app.staging.axiomtestlabs.co');
};

export const isEnvEuProd = () => {
  return window.location.host.includes('app.eu.axiom.co');
};

export const getEnv = (): ENV | undefined => {
  if (isEnvProd()) {
    return 'prod';
  } else if (isEnvStaging()) {
    return 'staging';
  } else if (isEnvDev()) {
    return 'dev';
  } else if (isEnvEuProd()) {
    return 'eu';
  }

  return undefined;
};
