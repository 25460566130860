// cSpell:ignore trackjs
import {
  CancelledError,
  type InvalidateQueryFilters,
  type QueryKey,
  QueryObserver,
  type UseQueryOptions,
} from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { action, computed, observable, when, makeObservable, type ObservableMap } from 'mobx';
import { TrackJS } from 'trackjs';

import { notification } from '../components/Notification';
import { queryClient } from '../contexts/TanstackQueryTrpc';
import { parseAxiosError, parseAxiosStatus } from '../util/axios';
import { hashCode } from '../util/elements/hashCode';
import { isProd } from '../util/isProd';
import { Logger } from '../util/logger';
import { isTimeoutError } from '../util/prettyError';
import { sentenceCaseMulti } from '../util/strings';

type Status = 'idle' | 'running' | 'finished';

interface StatusDetails {
  status: Status;
  finished: boolean; // status === 'finished'
  loading: boolean; // status !== 'finished'
  running: boolean; // status === 'running'
  error?: Error | string;
}

export class Operation {
  public runId: number = 1;

  @observable
  public status: Status;
  @observable
  public error?: Error | string;
  @observable
  public errorStatus?: number;
  @observable
  public errorData: any;
  @observable
  public conflict: boolean = false;
  @observable
  public disconnected: boolean = false;

  @computed
  public get timedOut(): boolean {
    const message = typeof this.error === 'string' ? this.error : undefined;

    return !!(message && isTimeoutError(message));
  }

  @observable
  private _hasRun: boolean = false;

  @observable
  public operationId: string | QueryKey;
  private unsubscribe?: () => void;
  private abortController?: AbortController;

  constructor(operationId: string | QueryKey, status: Status = 'idle', error?: Error) {
    makeObservable(this);
    this.status = status;

    when(
      () => this.finished,
      () => {
        this._hasRun = true;
      }
    );

    this.operationId = operationId;
  }

  @action.bound
  public clearErrors(): void {
    this.conflict = false;
    this.disconnected = false;
    this.error = undefined;
    this.errorData = undefined;
    this.errorStatus = undefined;
  }

  @computed
  public get finished(): boolean {
    return this.status === 'finished';
  }

  @computed
  public get hasRun(): boolean {
    // Have to look at both variables since `_hasRun` is updated in a `when` and the `when` won't run inside the outermost transaction.
    // https://mobx.js.org/reactions.html#rules
    // > Affected reactions run by default immediately (synchronously) if an observable is changed.
    // > However, they won't run before the end of the current outermost (trans)action.
    return this.finished || this._hasRun;
  }

  @computed
  public get loading(): boolean {
    return this.status !== 'finished';
  }

  @computed
  public get running(): boolean {
    return this.status === 'running';
  }

  @action.bound
  public start(): number {
    this.status = 'running';
    this.runId += 1;
    // Don't clear Errors until the operation has finished, to avoid flickering on retries.
    // operation.clearErrors();

    return this.runId;
  }

  @action.bound
  public success(): void {
    this.status = 'finished';
    this.clearErrors();
  }

  public dispose() {
    this.unsubscribe?.();
    this.abort();
  }

  public async useQuery<T>({
    queryFn,
    onSuccess,
    onError,
    ...options
  }: Omit<UseQueryOptions<T>, 'queryKey'> & {
    onSuccess: (data: T | undefined) => void;
    onError: (error: any) => any;
  }) {
    this.unsubscribe?.();

    const queryKey = this.queryKey;

    this.unsubscribe = new QueryObserver<T | undefined>(queryClient, {
      queryKey: queryKey,
      queryFn: queryFn,
      enabled: true,
      refetchOnMount: 'always',
      notifyOnChangeProps: 'all',
    }).subscribe((result) => {
      if (result.isSuccess) {
        onSuccess(result.data);
      } else if (result.isError) {
        onError(result.error);
      }
    });

    // it's safe to all ensure as we were given an actual queryFn
    return queryClient.ensureQueryData<T | undefined>({
      ...options,
      queryFn: queryFn,
      queryKey: queryKey,
      revalidateIfStale: true,
    });
  }

  public async invalidateQuery(options?: Omit<InvalidateQueryFilters, 'queryKey'>) {
    const queryKey = this.queryKey;

    return queryClient.invalidateQueries({
      queryKey: queryKey,
      exact: true,
      ...options,
    });
  }

  @action.bound
  public changeKey(operationId: string | QueryKey) {
    this.dispose();

    this.operationId = operationId;
  }

  public get queryKey(): QueryKey {
    return toJS(typeof this.operationId === 'string' ? [this.operationId] : this.operationId);
  }

  public getStatus(): StatusDetails {
    const status = this.status ?? 'idle';

    return {
      status: status,
      finished: status === 'finished',
      loading: status !== 'finished',
      running: status === 'running',
      error: this.error,
    };
  }

  public createSignal(): AbortSignal {
    this.abortController = new AbortController();

    return this.abortController.signal;
  }

  public abort() {
    this.abortController?.abort();
  }
}

export class OperationsTracker {
  public operations: Omit<ObservableMap<QueryKey, Operation>, 'get' | 'has'> = observable.map<QueryKey, Operation>();

  constructor(store: StoreBase | null) {
    makeObservable(this);

    store?.registerOperationTracker(this);
  }

  @computed
  public get loading(): boolean {
    let loading = false;
    this.operations.forEach((operation) => {
      if (operation.loading) {
        loading = true;

        return;
      }
    });

    return loading;
  }

  @computed
  public get running(): boolean {
    let running = false;
    this.operations.forEach((operation) => {
      if (operation.running) {
        running = true;

        return;
      }
    });

    return running;
  }

  public getStatus(operationId: string | QueryKey): StatusDetails {
    const operation = this.getOperation(operationId);

    const status = operation ? operation.status : 'idle';

    return {
      status: status,
      finished: status === 'finished',
      loading: status !== 'finished',
      running: status === 'running',
      error: operation ? operation.error : undefined,
    };
  }

  public getErrors(operationId?: string | QueryKey): (Error | string)[] {
    if (operationId) {
      const operation = this.getOperation(operationId);

      return operation?.error ? [operation.error] : [];
    } else {
      return Array.from(this.operations)
        .map(([key, value]) => {
          return value.error;
        })
        .filter((error) => error !== undefined);
    }
  }

  public clearErrors(operationId: string | QueryKey): void {
    const operation = this.getOperation(operationId);

    if (operation) {
      operation.clearErrors();
    }
  }

  @action.bound
  public getOrAddOperation(
    operationId: string | QueryKey,
    defaultValues = { status: 'idle' as Status, error: undefined }
  ): Operation {
    let operation: Operation | undefined = this.getOperation(operationId);
    if (!operation) {
      operation = new Operation(operationId, defaultValues.status, defaultValues.error);
      this.setOperation(operationId, operation);
    }

    return operation;
  }

  @action.bound
  public removeOperation(operationId?: string) {
    if (operationId) {
      this.deleteOperation(operationId);
    }
  }

  public dispose() {
    this.operations.forEach((operation) => {
      operation.dispose();
    });
  }

  private getOperation(operationId: string | QueryKey): Operation | undefined {
    const key_ = typeof operationId === 'string' ? [operationId] : operationId;
    const key = toJS(key_);
    for (const k of this.operations.keys()) {
      if (isEqual(k, key)) {
        return (this.operations as ObservableMap).get(k);
      }
    }

    return undefined;
  }

  private setOperation(operationId: string | QueryKey, operation: Operation) {
    const key = typeof operationId === 'string' ? [operationId] : operationId;
    this.operations.set(key, operation);
  }

  private deleteOperation(operationId: string | QueryKey) {
    const key = typeof operationId === 'string' ? [operationId] : operationId;
    this.operations.delete(key);
  }
}

export class SimpleOperationsTracker extends OperationsTracker {
  @observable
  public operation: Operation;

  constructor(store: StoreBase | null, operationId: string | QueryKey) {
    super(store);

    makeObservable(this);

    this.operation = this.getOrAddOperation(operationId);
  }

  @computed
  public get operationId(): string | QueryKey {
    return this.operation.operationId;
  }

  @computed
  public get status(): StatusDetails {
    return this.operation.getStatus();
  }
  @computed
  public get errors(): (Error | string)[] {
    return this.operation.error ? [this.operation.error] : [];
  }
  @computed
  public get finishedErrors(): (Error | string)[] {
    if (this.status.finished) {
      return this.getErrors(this.operationId);
    } else {
      return [];
    }
  }

  @action.bound
  public clearErrors(): void {
    this.operation.clearErrors();
  }
}

interface StoreBaseDefaults {
  onForbidden?(error: any): void;
}

export class StoreBase {
  static defaults: StoreBaseDefaults = {};
  private operationTrackers: OperationsTracker[] = [];

  constructor() {
    makeObservable(this);
  }

  @action.bound
  protected async operate<T = any>(
    operation: Operation,
    promise: Promise<T>,
    next?: (data: T) => void,
    errorNotifications: boolean = false,
    onError?: (error: any, op: Operation, traceId?: string) => boolean | undefined | void,
    toastId?: string,
    customCatch?: (reason: any) => T | undefined | PromiseLike<T | undefined>
  ): Promise<T | undefined> {
    const runId = operation.start();

    return promise
      .then(
        action((data: T) => {
          // If this operation has been started again before we finished, we don't want to change the status.
          // In theory we can also just skip `next`. Hopefully this doesn't cause issues 🤞️
          if (runId === operation.runId) {
            if (next) {
              next(data);
            }
            operation.success();
          }

          return data;
        })
      )
      .catch(
        action((error: any) => {
          // Ignore errors if we're not the most recent error.
          if (runId === operation.runId) {
            if (customCatch) {
              return customCatch(error);
            } else {
              return this.handleError(operation, error, errorNotifications, onError, toastId);
            }
          }

          return undefined;
        })
      );
  }

  @action.bound
  protected async operateRQ<T = any>(
    operation: Operation,
    queryFn: () => Promise<T>,
    next?: (data: T) => void,
    errorNotifications: boolean = false,
    onError?: (error: any, op: Operation) => boolean | undefined | void,
    toastId?: string,
    customCatch?: (reason: any) => T | undefined | PromiseLike<T | undefined>,
    useQueryOptions?: Omit<UseQueryOptions<T>, 'queryFn'>
  ): Promise<T | undefined> {
    const { queryKey } = useQueryOptions ?? {};

    operation.start();

    if (queryKey) {
      operation.changeKey(queryKey);
    }

    const handleSuccess = action((data: T) => {
      if (next) {
        next(data);
      }
      operation.success();

      return data;
    });

    const handleError = action((error: any) => {
      if (error instanceof CancelledError) {
        Logger.debug('Ignoring cancelled error', error);

        return;
      }

      if (customCatch) {
        return customCatch(error);
      } else {
        return this.handleError(operation, error, errorNotifications, onError, toastId);
      }
    });

    const queryPromise = operation
      .useQuery<T>({
        queryFn: queryFn,
        onSuccess: handleSuccess,
        onError: handleError,
      })
      .then(handleSuccess)
      .catch(handleError);

    return queryPromise;
  }

  protected handleError(
    operation: Operation,
    err: any,
    errorNotifications: boolean = false,
    onError?: (error: any, op: Operation) => boolean | undefined | void,
    toastId?: string
  ) {
    console.trace('Error during StoreBase.operate', err);

    if (!err?.isAxiosError) {
      TrackJS.track(err);
    }

    operation.clearErrors();
    operation.status = 'finished';
    operation.error = err || 'Unknown error';

    if (err?.response?.data) {
      operation.errorData = err.response.data;
    }

    const message = parseAxiosError(err, true);
    if (message) {
      // Use a better message for "Network Error".
      if (message === 'Network Error') {
        operation.disconnected = true;
        operation.error = 'You are disconnected.';
        // In dev mode, show what endpoint failed.
      } else if (err.code === 'ECONNABORTED' && !isProd) {
        operation.error = `The request to ${err.config.url} timed out.`;
      } else {
        operation.error = message;
      }
    }

    operation.errorStatus = parseAxiosStatus(err);
    if (operation.errorStatus === 409) {
      operation.conflict = true;
    }
    if (operation.errorStatus === 403 && StoreBase.defaults.onForbidden) {
      StoreBase.defaults.onForbidden(err);
    }

    let onErrorResult: boolean | undefined | void;
    if (onError) {
      onErrorResult = onError(err, operation);
    }

    // Use the result of onError if it returned a boolean.
    const actualErrorNotifications = typeof onErrorResult === 'boolean' ? onErrorResult : errorNotifications;

    if (actualErrorNotifications) {
      const notificationKey = toastId || `${hashCode(String(operation.error))}-${operation.status}`;
      notification.error(
        {
          message: 'Error',
          description: operation.error ? sentenceCaseMulti(String(operation.error)) : undefined,
        },
        {
          toastId: notificationKey,
          autoClose: false,
        }
      );
    }

    return undefined;
  }

  // Could probably just get rid of this function and call `notification.dismiss` directly, but doesn't seem to hurt (yet) either.
  protected dismissError(toastId: string) {
    notification.dismiss(toastId);
  }

  public registerOperationTracker(tracker: OperationsTracker) {
    this.operationTrackers.push(tracker);
  }

  public dispose() {
    this.operationTrackers.forEach((tracker) => {
      tracker.dispose();
    });
  }
}
