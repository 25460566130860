// Use the webpack provided global to set the public path at runtime
// https://v4.webpack.js.org/guides/public-path/#on-the-fly
// eslint-disable-next-line no-var
declare var __webpack_public_path__: string;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
__webpack_public_path__ = `${(window as any).assetUrl || '/static'}/`;

// eslint-disable-next-line import/order
import './init/common';

// cSpell:disable-next-line
import { loadLocaleData, type LocaleData } from 'langs/loadLocaleData';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { notification } from './components/Notification';
import './init';
import { MainRouter } from './MainRouter';
import { dashApi } from './shared/dash';
import { StoreBase } from './stores/StoreBase';
import { viewStore } from './stores/ViewStore';
import { Logger } from './util/logger';
import { signOut } from './util/next-auth';

let locales: string[] = [navigator.language];
if (navigator.languages?.length) {
  locales = [...navigator.languages];
}

loadLocaleData(locales, (localeData: LocaleData, isDefault: boolean) => {
  if (isDefault) {
    Logger.warn(`Unable to match ${locales.join(' ')}, falling back to ${localeData.locale}`);
  }

  const container = document.getElementById('root');

  if (!container) {
    throw new Error('Root element not found');
  }

  const root = createRoot(container);
  root.render(<MainRouter />);
});

// If we have a flash error message, display it.
// Unless error_description is present which means this is an OAuth thing and we can ignore it.
if (viewStore.activeQuery.error && !viewStore.activeQuery.error_description) {
  notification.error({ message: 'Error', description: viewStore.activeQuery.error }, { autoClose: false });
}

export const defaultOnForbidden = (error: any) => {
  // Use pretty much any API call that requires authorization but doesn't require an Org to test our access token still works.
  dashApi.getOrgs().catch(async () => {
    await signOut();
  });
};

// If any API call fails with "Forbidden", then make sure we're still logged in.
StoreBase.defaults.onForbidden = defaultOnForbidden;

if (module.hot) {
  module.hot.accept(); // Accept updates for all modules
}
