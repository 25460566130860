import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { cn } from '../util/styles';

type ClickablePropsType = Omit<React.HTMLAttributes<HTMLElement>, 'onClick' | 'onKeyDown'>;

export interface ClickableProps extends ClickablePropsType {
  disabled?: boolean;
  elementType?: string;
  onClick(event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>): void;
}

const handleClick = (
  disabled: boolean | undefined,
  onClick: (event: React.MouseEvent<HTMLElement>) => void,
  event: React.MouseEvent<HTMLElement>
): void => {
  if (!disabled) {
    onClick(event);
  }
};

// This was originally onKeyPress but that fires too late to prevent other things from also handling the key.
// For instance on the Edit Monitor Form, if you use "enter" on the "Add notifier" button the `onFormKeyDown` event
// is firing before us so onKeyPress never fired.
const handleKeyDown = (ref: React.RefObject<HTMLDivElement>, event: React.KeyboardEvent<HTMLSpanElement>): void => {
  if (event.key === 'Enter' || event.key === ' ') {
    if (ref.current) {
      // Stop propagation on the Enter press.
      event.nativeEvent.stopImmediatePropagation();
      event.stopPropagation();
      // Need for the "Add" button used in EditableTagList (possibly other scenarios)
      // Without it, the Autocomplete will get a space in the Input.
      event.preventDefault();

      // Instead of just calling props.onClick, fire a real click event so it propagates.
      ref.current.click();
    }
  }
};

export const Clickable = forwardRef<HTMLElement, ClickableProps>(
  ({ className, disabled, elementType, onClick, title, ...passthrough }, outerRef) => {
    const innerRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(outerRef, () => innerRef.current!, []);

    return React.createElement(elementType || 'span', {
      ref: innerRef,
      onClick: (event: React.MouseEvent<HTMLElement>) => handleClick(disabled, onClick, event),
      onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => handleKeyDown(innerRef, event),
      tabIndex: 0,
      disabled: disabled,
      title: title,
      ['aria-label']: title,
      ['aria-disabled']: disabled,
      role: 'button',
      className: cn('cursor-pointer disabled:cursor-not-allowed disabled:opacity-disabled', className),
      ...passthrough,
    });
  }
);

Clickable.displayName = 'Clickable';
