import { useLocalObservable } from 'mobx-react-lite';
import { useEffect } from 'react';

import { axiomDebug } from '../../axiomDebug';

export const useDebuggedLocalObservable: typeof useLocalObservable = (fn, annotations) => {
  const store = useLocalObservable(fn, annotations);

  useEffect(() => {
    axiomDebug(store);
  }, [store]);

  return store;
};
