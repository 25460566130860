import { toJS } from 'mobx';

// eslint-disable-next-line no-var
declare var window: any;

// Add toJS to global scope to aid in debugging.
window.toJS = toJS;

export function axiomDebug(store: any) {
  // CD: Going to always enable debugging for now. Keep going to use it on instances and it's not there.
  // some tests rely on stores being exposed so always enable if E2E_TESTING is true
  // const E2E_TESTING = process.env.E2E_TESTING === 'true';
  // process.env.AXIOM_DEBUG === 'true' || window.axiomDebug || E2E_TESTING || new URL(window.location.href).searchParams.get('AXIOM_DEBUG')
  if (store?.constructor?.name) {
    const storeName = store.constructor.name.charAt(0).toLowerCase() + store.constructor.name.slice(1);
    window.axiomStores = window.axiomStores || {};
    window.axiomStores[storeName] = store;
  }
}

export function isDebugMode() {
  return (
    process.env.AXIOM_DEBUG === 'true' ||
    window.axiomDebug ||
    new URL(window.location.href).searchParams.get('AXIOM_DEBUG')
  );
}
