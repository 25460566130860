// cSpell:ignore signout
/* eslint-disable @typescript-eslint/no-floating-promises */
import { type SessionContextValue, signOut as nextAuthSignOut } from 'next-auth/react';

import { axiom } from '../axiom';
import { queryClient } from '../contexts/TanstackQueryTrpc';
import { dashApi } from '../shared/dash';

import { clearIndexedDB } from './reactQuery/idb';

export async function signOut() {
  try {
    // Make a post to Login to delete the current user token.
    await dashApi.deleteCurrentUserToken();
  } catch (err) {
    // These should be safe to ignore.
    // Hopefully it's just an edge case
    // where the user is still logged in
    // to next-auth but not the API.
    console.error(err);
  }

  // delete all cached React Query data
  queryClient.clear();

  // wipe indexeddb
  void clearIndexedDB();

  //  Do the normal next-auth Sign Out.
  nextAuthSignOut();
}

export function axiomGetAccessToken(session: SessionContextValue): string | undefined {
  if (isPlayMode()) {
    if (!axiom.playModeReadonlyToken) {
      throw new Error('axiom.playModeReadonlyToken is not configured.');
    }

    return axiom.playModeReadonlyToken;
  } else {
    const token = (session.data as unknown as any)?.accessToken;

    return String(token) || undefined;
  }
}

const isPlayMode = () => {
  return axiom.playMode || isPlayServer() || false;
};

const isPlayServer = () => {
  const hostname = window.location.hostname;

  return (hostname || '').toLowerCase().startsWith('play.');
};
