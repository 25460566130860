import React, { Fragment, useId } from 'react';
import { type FieldError } from 'react-hook-form';

import { cn } from '../../util/styles';
import { FormItemContext, useFormField } from '../primitives/Form';

import { FormItemHelper } from './FormItemHelper';
import { FormLabel, type FormLabelVariant } from './FormLabel';

import styles from '../FormItem.module.scss';

interface FormItemProps {
  className?: string;
  formLabelId?: string;
  helperText?: React.ReactNode;
  helperClassName?: string;
  helperInline?: boolean;
  horizontal?: boolean;
  itemClassName?: string;
  label?: string;
  labelClassName?: string;
  labelFor?: string;
  labelVariant?: FormLabelVariant;
  required?: boolean;
  children?: React.ReactNode;
}

export function FormItem(props: FormItemProps) {
  const { children, className, ...passthrough } = props;

  const { error, formMessageId } = useFormField();

  const id = useId();

  return (
    <FormItemContext.Provider value={{ id: id }}>
      <FormItemVanilla className={className} error={error} formMessageId={formMessageId} {...passthrough}>
        {children}
      </FormItemVanilla>
    </FormItemContext.Provider>
  );
}

interface FormItemVanillaProps extends FormItemProps {
  error?: FieldError | string;
  formMessageId?: string;
}

/**
 * Extracted version of FormField that doesn't require being inside a hook-form.
 */
export function FormItemVanilla(props: FormItemVanillaProps) {
  const {
    children,
    className,
    error,
    formLabelId,
    horizontal,
    itemClassName,
    label,
    labelClassName,
    labelFor,
    labelVariant,
    required,
    helperClassName,
    helperText,
    formMessageId,
    helperInline,
  } = props;

  return (
    <Fragment>
      <div
        className={cn(
          'form-item', // Global style so BasicForm can set margin on last child.
          'mb-4 [.form-section_&:last-child]:mb-0',
          !!helperInline && 'mb-0',
          { [styles.horizontal]: horizontal, [styles.error]: Boolean(error) },
          className
        )}
      >
        {label ? (
          <FormLabel
            id={formLabelId}
            htmlFor={labelFor}
            className={cn(
              styles.label,

              // @ts-expect-error @todo: fix this
              styles[`label-${labelVariant || 'default'}`],
              labelClassName
            )}
          >
            {required ? '* ' : null}
            {label}
          </FormLabel>
        ) : null}
        <div className={cn(!!helperInline && 'flex items-end gap-2')}>
          <div className={cn(styles.item, itemClassName)}>{children}</div>
          {helperText || error ? (
            <FormItemHelper
              className={helperClassName}
              helperText={helperText ?? undefined}
              formMessageId={formMessageId}
              error={error}
            />
          ) : null}
        </div>
      </div>
    </Fragment>
  );
}
