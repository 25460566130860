/* eslint-disable */
/* THIS IS A GENERATED FILE: FUCK OFF */
module.exports = { 
  en: () => { return require('./en'); },
  cs: () => { return require('./cs'); },
  de: () => { return require('./de'); },
  es: () => { return require('./es'); },
  fr: () => { return require('./fr'); },
  hu: () => { return require('./hu'); },
  it: () => { return require('./it'); },
  ja: () => { return require('./ja'); },
  ko: () => { return require('./ko'); },
  nl: () => { return require('./nl'); },
  pl: () => { return require('./pl'); },
  pt: () => { return require('./pt'); },
  ru: () => { return require('./ru'); },
  sv: () => { return require('./sv'); },
  tr: () => { return require('./tr'); },
  zh: () => { return require('./zh'); },
 };
