// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pqVl0bBSduxl9OZ_x_2Q {\n  color: var(--axi-variant-red-color);\n}\n\n.YN6g_MwglIzZSZgFpum5 {\n  animation-duration: 200ms;\n  animation-fill-mode: both;\n  animation-iteration-count: 1;\n  animation-timing-function: linear;\n  animation-name: fadeIn;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: center;\n  justify-content: center;\n}\n\n.Y5RaarbVd_eioUJkixww {\n  opacity: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": "pqVl0bBSduxl9OZ_x_2Q",
	"loading": "YN6g_MwglIzZSZgFpum5",
	"hidden": "Y5RaarbVd_eioUJkixww"
};
export default ___CSS_LOADER_EXPORT___;
