/* eslint-disable no-console */
// cSpell:ignore fileinfo

import { isProd } from './isProd';
import { isNullOrEmpty } from './strings';

// Produces `$filename[$func]`
function callSig(): string {
  try {
    const line = (new Error().stack || '').split('\n')[3].split('(');
    const func = line[0].trim().replace(/at /, '');
    let filename = line[1];
    filename = filename.substring(0, filename.length - 1);
    filename = (filename.split('/').pop() || '').replace(/\?/, '');
    filename = filename.substring(0, filename.indexOf(':'));

    return `${filename}[${func}]`;
  } catch (e) {
    return 'no-fileinfo';
  }
}

function debug(msg: string, ...optionalParams: any[]) {
  if (!isProd) {
    console.log(`${callSig()} ${msg}`, optionalParams);
  }
}

function info(msg: string, ...optionalParams: any[]) {
  console.info(`${callSig()} ${msg}`, optionalParams);
}

function warn(msg: string, ...optionalParams: any[]) {
  console.warn(`${callSig()} ${msg}`, optionalParams);
}

function error(msg: string, ...optionalParams: any[]) {
  console.error(`${callSig()} ${msg}`, optionalParams);
}

function stack(msg: string, ...optionalParams: any[]) {
  if (isNullOrEmpty(msg)) {
    console.warn(`${callSig()} ${msg}`, optionalParams);
  }
  console.trace();
}

export const Logger = {
  debug: debug,
  info: info,
  warn: warn,
  error: error,
  stack: stack,
};
