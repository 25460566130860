import React from 'react';

interface NewLinesToBreaksProps {
  text?: string;
}

export const NewLinesToBreaks = ({ text = '' }: NewLinesToBreaksProps) => {
  return (
    <React.Fragment>
      {text.split('\n').map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item}
            <br />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
