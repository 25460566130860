

module.exports = function loaderde(locale, cb) {
  require.ensure([
      'moment/locale/de-at', 'moment/locale/de-ch', 'moment/locale/de'
      , 'numbro/dist/languages/de-AT.min', 'numbro/dist/languages/de-CH.min', 'numbro/dist/languages/de-DE.min', 'numbro/dist/languages/de-LI.min'
      , 'sugar-date/locales/de'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/de-at');
      require('moment/locale/de-ch');
      require('moment/locale/de');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const numbro = require('numbro');
      numbro.registerLanguage(require('numbro/dist/languages/de-AT.min'));
      numbro.registerLanguage(require('numbro/dist/languages/de-CH.min'));
      numbro.registerLanguage(require('numbro/dist/languages/de-DE.min'));
      numbro.registerLanguage(require('numbro/dist/languages/de-LI.min'));
      numbro.setLanguage(locale);

    const Sugar = require('sugar-date');
      require('sugar-date/locales/de');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-de');
};

