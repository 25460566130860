import * as LabelPrimitive from '@radix-ui/react-label';
import * as React from 'react';

import { cn } from '../../util/styles';
import { Label } from '../primitives/Label';
import { type RadixClassNameTypeHelper } from '../primitives/temporaryRadixTypeHelper';

import styles from '../FormLabel.module.scss';

export type FormLabelVariant = 'default' | 'heading' | 'strong';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    RadixClassNameTypeHelper<{
      variant?: FormLabelVariant;
    }>
>(({ className, variant, ...passthrough }, ref) => {
  return (
    <Label
      ref={ref}
      className={cn(styles.label, styles[`label-${variant || 'default'}`], className)}
      {...passthrough}
    />
  );
});

FormLabel.displayName = LabelPrimitive.Root.displayName;

export { FormLabel };
