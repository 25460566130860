// cSpell:ignore axiomtestlabs kusto Longname withRBAC withAuths

import { axiom } from './axiom';
import { initFaro } from './util/faro';

const E2E_TESTING = process.env.E2E_TESTING === 'true';

function getWorker(moduleId: any, label: any) {
  const workers = axiom.workers;
  let worker = workers.find((config) => config.label === label);

  // Default to the first one if no match was found
  if (!worker && workers.length) {
    worker = workers[0];
  }

  return worker;
}

// `editor.api.ts` now checks for the `MonacoEnvironment.globalAPI` variable to see if it should
// set `window.monaco`, and since `@kusto/monaco-kusto` expects `window.monaco` to be set,
// we need to set `MonacoEnvironment` before `monaco-editor` loads.
(window as any).MonacoEnvironment = {
  globalAPI: true,
};

if (E2E_TESTING) {
  // Testing lib doesn't support blob urls that are used in production
  // See: https://github.com/puppeteer/puppeteer/issues/6698
  (window as any).MonacoEnvironment.getWorkerUrl = function (moduleId: any, label: any) {
    return getWorker(moduleId, label)?.url;
  };
} else {
  // Browsers won't load a worker when served from a different origin but should work if constructed using a blob url.
  // See: https://stackoverflow.com/questions/58099138/getting-failed-to-construct-worker-with-js-worker-file-located-on-other-domain
  (window as any).MonacoEnvironment.getWorker = function (moduleId: any, label: any) {
    const worker = getWorker(moduleId, label);

    if (worker) {
      const base = worker.url.startsWith('http') ? undefined : window.location.origin;
      const url = new URL(worker.url, base).href;
      const iss = `importScripts("${url}");`;

      return new Worker(URL.createObjectURL(new Blob([iss])));
    }

    return undefined;
  };
}

initFaro();
