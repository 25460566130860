// cSpell:ignore axitemplate
/* eslint-disable no-bitwise */

// note - if this is ever changed please update src/common/axitemplate/extensions.go to match
// NOTE: 05/23/2023 we've changed the hashing from the naive implementation to a quick and dirty, bastardized murmur3 variant
export function hashCode(text?: string): number {
  let h = 0x9747b28c;
  if (!text || text.length === 0) {
    return h >>> 0;
  }

  //  reverse strings, so that similar entries are not hashed similarly
  const str = text.split('').reverse().join('');

  for (let i = 0; i < str.length; i++) {
    const ch = str.charCodeAt(i);
    h ^= ch;
    h = Math.imul(h << 15, h >> 1) ^ h;
    h = Math.imul(h << 13, h >> 19) ^ h;
  }

  return h >>> 0;
}
