import { type ENV } from './env';

export const axiomTraceUrl = ({ env, traceId }: { env: ENV; traceId: string }) => {
  switch (env) {
    case 'dev':
      return `https://app.dev.axiomtestlabs.co/axiomers-ft83/dashboards/otel.traces.axiom-traces-dev?traceDataset=axiom-traces-dev&traceId=${traceId}&v_trace_id_search=${traceId}`;
    case 'staging':
      return `https://app.staging.axiomtestlabs.co/axiomers-6qt8/dashboards/otel.traces.axiom-traces-staging?traceDataset=axiom-traces-staging&traceId=${traceId}&v_trace_id_search=${traceId}`;
    case 'prod':
      return `https://app.staging.axiomtestlabs.co/axiomers-6qt8/dashboards/otel.traces.axiom-traces-prod?traceDataset=axiom-traces-prod&traceId=${traceId}&v_trace_id_search=${traceId}`;
    case 'eu':
      return `https://app.staging.axiomtestlabs.co/axiomers-6qt8/dashboards/otel.traces.axiom-traces-eu-prod?traceDataset=axiom-traces-eu-prod&traceId=${traceId}&v_trace_id_search=${traceId}`;
  }
};
