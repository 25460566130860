/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AlertHistory, GetMonitorHistoryParams, Monitor, Notifier } from "./data-contracts";
import { HttpClientV2, RequestParams } from "./http-client";

export class MonitorsApi<SecurityDataType = unknown> extends HttpClientV2<SecurityDataType> {
  /**
   * @description Get monitors
   *
   * @tags Monitors
   * @name GetMonitors
   * @request GET:/monitors
   * @secure
   * @response `200` `(Monitor)[]` Monitor
   */
  getMonitors = (params: RequestParams = {}) =>
    this.request<Monitor[], any>({
      path: `/monitors`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create monitor
   *
   * @tags Monitors
   * @name CreateMonitor
   * @request POST:/monitors
   * @secure
   * @response `200` `Monitor` Monitor
   */
  createMonitor = (payload: Monitor, params: RequestParams = {}) =>
    this.request<Monitor, any>({
      path: `/monitors`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get monitor by ID
   *
   * @tags Monitors
   * @name GetMonitor
   * @request GET:/monitors/{id}
   * @secure
   * @response `200` `Monitor` Monitor
   */
  getMonitor = (id: string, params: RequestParams = {}) =>
    this.request<Monitor, any>({
      path: `/monitors/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update monitor
   *
   * @tags Monitors
   * @name UpdateMonitor
   * @request PUT:/monitors/{id}
   * @secure
   * @response `200` `Monitor` Monitor
   */
  updateMonitor = (id: string, payload: Monitor, params: RequestParams = {}) =>
    this.request<Monitor, any>({
      path: `/monitors/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Delete monitor
   *
   * @tags Monitors
   * @name DeleteMonitor
   * @request DELETE:/monitors/{id}
   * @secure
   * @response `204` `void` Success
   */
  deleteMonitor = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/monitors/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * @description Get monitor history
   *
   * @tags Monitors
   * @name GetMonitorHistory
   * @request GET:/monitors/{id}/history
   * @secure
   * @response `200` `(AlertHistory)[]` AlertHistory
   */
  getMonitorHistory = ({ id, ...query }: GetMonitorHistoryParams, params: RequestParams = {}) =>
    this.request<AlertHistory[], any>({
      path: `/monitors/${id}/history`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get notifiers
   *
   * @tags Monitors
   * @name GetNotifiers
   * @request GET:/notifiers
   * @secure
   * @response `200` `(Notifier)[]` Notifier
   */
  getNotifiers = (params: RequestParams = {}) =>
    this.request<Notifier[], any>({
      path: `/notifiers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Create notifier
   *
   * @tags Monitors
   * @name CreateNotifier
   * @request POST:/notifiers
   * @secure
   * @response `200` `Notifier` Notifier
   */
  createNotifier = (payload: Notifier, params: RequestParams = {}) =>
    this.request<Notifier, any>({
      path: `/notifiers`,
      method: "POST",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Get notifier by ID
   *
   * @tags Monitors
   * @name GetNotifier
   * @request GET:/notifiers/{id}
   * @secure
   * @response `200` `Notifier` Notifier
   */
  getNotifier = (id: string, params: RequestParams = {}) =>
    this.request<Notifier, any>({
      path: `/notifiers/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Update notifier
   *
   * @tags Monitors
   * @name UpdateNotifier
   * @request PUT:/notifiers/{id}
   * @secure
   * @response `200` `Notifier` Notifier
   */
  updateNotifier = (id: string, payload: Notifier, params: RequestParams = {}) =>
    this.request<Notifier, any>({
      path: `/notifiers/${id}`,
      method: "PUT",
      body: payload,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description Delete notifier
   *
   * @tags Monitors
   * @name DeleteNotifier
   * @request DELETE:/notifiers/{id}
   * @secure
   * @response `204` `void` (empty)
   */
  deleteNotifier = (id: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/notifiers/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
