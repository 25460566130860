import * as LabelPrimitive from '@radix-ui/react-label';
import * as React from 'react';

import { type RadixClassNameTypeHelper } from './temporaryRadixTypeHelper';

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & RadixClassNameTypeHelper
>(({ className, ...props }, ref) => <LabelPrimitive.Root ref={ref} className={className} {...props} />);

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
