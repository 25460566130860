import React from 'react';
import { type ControllerProps, type FieldPath, type FieldValues } from 'react-hook-form';

import { cn } from '../../util/styles';
import { FormControl, FormField, FormLabel, useFormField } from '../primitives/Form';
import { RadioGroup, type RadioGroupProps, RadioGroupItem } from '../primitives/RadioGroup';

import { FormItem } from './FormItem';
import { type FormLabelVariant } from './FormLabel';

type FormRadioGroupVariant = 'button' | 'radio';

interface FormRadioGroupProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, 'render' | 'defaultValue'>,
    Omit<RadioGroupProps, 'name' | 'labelledBy' | 'onChange' | 'value'> {
  helperClassName?: string;
  horizontal?: boolean;
  label?: string;
  labelVariant?: FormLabelVariant;
  required?: boolean;
  rootClassName?: string;
  variant: FormRadioGroupVariant;
}

const FormRadioGroup = React.forwardRef<HTMLInputElement, FormRadioGroupProps>(
  (
    {
      children,
      control,
      helperClassName,
      horizontal,
      label,
      labelVariant,
      name,
      required,
      rootClassName,
      variant,
      ...passthrough
    },
    ref
  ) => {
    const { error } = useFormField();

    return (
      <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem
            className={rootClassName}
            formLabelId={name}
            helperText={error?.message}
            helperClassName={helperClassName}
            horizontal={horizontal}
            label={label}
            labelVariant={labelVariant}
            required={required}
          >
            <FormControl>
              <RadioGroup
                className={cn({ 'flex flex-wrap': variant === 'button' }, { 'flex flex-col': variant === 'radio' })}
                onValueChange={field.onChange}
                defaultValue={field.value}
                {...passthrough}
              >
                {children}
              </RadioGroup>
            </FormControl>
          </FormItem>
        )}
      />
    );
  }
);

FormRadioGroup.displayName = 'FormRadioGroup';

const FormRadioGroupItem = ({
  children,
  value,
  className,
}: {
  children: React.ReactNode;
  value: string;
  className?: string;
}) => {
  return (
    <FormItem itemClassName={cn('flex flex-row items-center justify-start space-x-3 space-y-0', className)}>
      <div className="flex flex-row items-center gap-2">
        <FormControl>
          <RadioGroupItem value={value} />
        </FormControl>
        <FormLabel className="font-normal">{children}</FormLabel>
      </div>
    </FormItem>
  );
};

export { FormRadioGroup, FormRadioGroupItem };
