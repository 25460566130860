// cSpell:ignore Segoe pako ungzip

import pako from 'pako';

export function isNullOrEmpty(s?: string): boolean {
  if (!s || s.length < 1 || s === '' || s.trim().length === 0) {
    return true;
  }

  return false;
}

export function isNotNullOrEmpty(s?: string): boolean {
  return isNullOrEmpty(s) === false;
}

export function searchSort<T>(search: string, key: string = '', nearest?: boolean) {
  return (aa: T, bb: T) => {
    // @ts-expect-error we don't want to make the function signature more strict becuase it should be possible to call it loosely
    const aaString = aa[key] ? aa[key].toString() : aa.toString();
    // @ts-expect-error we don't want to make the function signature more strict becuase it should be possible to call it loosely
    const bbString = bb[key] ? bb[key].toString() : bb.toString();

    // sort strings that startWith the search term first
    const aaSearchIdx = aaString.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());
    const bbSearchIdx = bbString.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());

    const aaStartWithSearch = aaSearchIdx === 0;
    const bbStartWithSearch = bbSearchIdx === 0;

    if (aaStartWithSearch && !bbStartWithSearch) {
      return -1;
    } else if (bbStartWithSearch && !aaStartWithSearch) {
      return 1;
    } else if (nearest) {
      if (aaSearchIdx !== -1 && bbSearchIdx === -1) {
        return -1;
      } else if (bbSearchIdx !== -1 && aaSearchIdx === -1) {
        return 1;
      } else if (aaSearchIdx !== bbSearchIdx) {
        return aaSearchIdx < bbSearchIdx ? -1 : 1;
      }
    }

    return aaString.localeCompare(bbString);
  };
}

export function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * the `alwaysEscape` parameter is useful for example for map fields:
 * e.g. for a map field `foo` with attribute `bar`, we want `['foo']['bar']`, not `foobar`
 * // `foo['bar'] should also be possible afaik, but standardizing on escaping both parts of map fields for now, feel free to change :)
 */
export const escapeStrForApl = (str: string, alwaysEscape = false) => {
  if (alwaysEscape) {
    return `['${str.replace(/\\/g, '\\\\')}']`;
  }

  return str.match(
    /^(?!and|as|avg|bin|bool|by|cnt|consume|contains|containscs|count|countof|date|datetime|distinct|evaluate|extend|extentid|extract|extractjson|facet|false|filter|find|floor|fork|getschema|has|iif|int|invoke|isempty|isnotempty|isnotnull|isnull|join|limit|long|lookup|matches|max|min|notcontains|notcontainscs|notempty|notnull|now|on|or|order|parse|partition|print|project|range|re2|real|reduce|regex|render|sample|scan|search|serialize|sort|startswith|strcat|string|strlen|sum|summarize|take|time|top|tostring|toupper|true|typeof|union|where|boolean$)[a-zA-Z_][a-zA-Z0-9_]*$/
  )
    ? str
    : `['${str.replace(/\\/g, '\\\\')}']`;
};

/**
 * Removes all non-numeric characters from a string, removes leading zeros
 */
export const ParseStringToNumberalString = (inputValue: string) => {
  return inputValue
    .replace(/[^0-9.-]/g, '') // Don't allow non-numeric values. But allow - and .
    .replace(/^0+/, ''); // remove leading 0s
};

export const removeDatasetBracketsIfExist = (str: string) => str.replace(/^\['(.*)'\]$/, '$1');

export const pluralize = (count: number, noun: string, prefix: string | number, suffix = 's') => {
  return `${prefix} ${noun}${count !== 1 ? suffix : ''}`;
};

export const PROTOCOL_REGEX = /^https?:\/\//i;

export function deflate(data: string): string | undefined {
  try {
    const bin = pako.gzip(data);

    return btoa(String.fromCharCode(...bin));
  } catch (err) {
    console.warn('Unable to deflate data', err);
  }

  return undefined;
}

export function inflate(data: string): string | undefined {
  try {
    const bin = new Uint8Array(
      atob(data)
        .split('')
        .map((c) => c.charCodeAt(0))
    );

    return pako.ungzip(bin, { to: 'string' });
  } catch (err) {
    console.warn('Unable to inflate data', err);
  }

  return undefined;
}

export function isDeflated(str: string) {
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}

// Convert someCamelCase string into 'Some camel case'
export function sentenceCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before capital letters
    .toLowerCase()
    .replace(/^./, (s) => s.toUpperCase()); // Capitalize the first letter
}

// sentenceCase across multiple sentences in a string, eg: 'some thing. another thing' into 'Some thing. Another thing'
export function sentenceCaseMulti(str: string): string {
  return str
    .split('. ')
    .map((n) => sentenceCase(n))
    .join('. ');
}

export function trimStringMiddle(input: string, maxLength: number): string {
  if (input.length <= maxLength) {
    return input;
  }

  const keepLength = Math.floor((maxLength - 3) / 2);

  const beginning = input.substring(0, keepLength);
  const end = input.substring(input.length - keepLength);

  return `${beginning}...${end}`;
}

export function stringifyBoolean(value: boolean): string {
  if (value === undefined || value === null) {
    return 'false';
  }

  return value.toString();
}

/**
 * containsTimeFilter is set to `'true'` or `'false'`
 */
export function parseBooleanString(value: string | boolean | undefined, fallback?: boolean): boolean {
  if (fallback !== undefined && value !== 'true' && value !== 'false' && value !== true) {
    return fallback;
  }

  return value === 'true' || value === true;
}

export function kebabCaseToCamelCase(str: string): string {
  const trimmedStr = str
    // Remove leading and trailing hyphens
    .replace(/^-+|-+$/g, '')
    // turn `foo-1bar` into `foo1bar`
    .replace(/-(\d)/g, (_, digit) => digit)
    // Convert the remaining kebab-case string to camelCase
    .replace(/-([a-z])/g, (_, letter: string) => letter.toUpperCase());

  return trimmedStr;
}

export type KebabToCamelCase<S extends string> = S extends `${infer T}-${infer U}`
  ? `${T}${Capitalize<KebabToCamelCase<U>>}`
  : S;
