import { action, makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { createContext, useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { useRequiredContext } from '../../util/hooks/util/useRequiredContext';

/**
 * Update this dictionary to add new portals.
 * Used to keep track of portal names and avoid clashes.
 */
const PORTAL_DICTIONARY = {
  in: [],
  out: ['intentionallyEmpty'],
} as const;

type InPortalName = (typeof PORTAL_DICTIONARY)['in'][number];
type OutPortalName = (typeof PORTAL_DICTIONARY)['out'][number];

type InPortalType = {
  name: InPortalName;
  intendedOut: string;
};
type OutPortalType = {
  name: OutPortalName;
  ref: React.RefObject<HTMLElement> | null;
};

export class PortalStore {
  @observable
  inPortals = new Map<InPortalName, InPortalType>();

  @observable
  outPortals = new Map<OutPortalName, OutPortalType>();

  constructor() {
    makeObservable(this);
  }

  @action.bound
  public addInPortal(portal: InPortalType) {
    if (this.inPortals.has(portal.name)) {
      console.error(`InPortal with name ${portal.name} already exists.`);

      return;
    }

    this.inPortals.set(portal.name, portal);
  }

  @action.bound
  removeInPortal(name: InPortalName) {
    this.inPortals.delete(name);
  }

  @action.bound
  addOutPortal(portal: OutPortalType) {
    if (this.outPortals.has(portal.name)) {
      console.error(`OutPortal with name ${portal.name} already exists.`);

      return;
    }
    this.outPortals.set(portal.name, portal);
  }

  @action.bound
  removeOutPortal(name: OutPortalName) {
    this.outPortals.delete(name);
  }
}

export const PortalStoreContext = createContext<PortalStore | null>(null);
PortalStoreContext.displayName = 'PortalStoreContext';

const useRegisterInPortal = ({ name, intendedOut }: { name: InPortalName; intendedOut: string }) => {
  const { addInPortal, removeInPortal } = useRequiredContext(PortalStoreContext);

  useLayoutEffect(() => {
    const portal = { name: name, intendedOut: intendedOut };
    addInPortal(portal);

    return () => {
      removeInPortal(portal.name);
    };
  }, [name, intendedOut, addInPortal, removeInPortal]);
};

export const useRegisterOutPortal = ({
  name,
  ref,
}: {
  name: OutPortalName;
  ref: React.RefObject<HTMLElement> | null;
}) => {
  const { addOutPortal, removeOutPortal } = useRequiredContext(PortalStoreContext);

  useLayoutEffect(() => {
    const portal = { name: name, ref: ref };
    addOutPortal(portal);

    return () => {
      removeOutPortal(portal.name);
    };
  }, [name, ref, addOutPortal, removeOutPortal]);
};

/**
 * Children passed to this component will be behave as if they were mounted here (they are),
 * but will be rendered as children of the matching `OutPortal` in the DOM.
 */
export const InPortal = observer(function InPortal({
  children,
  name,
  outPortalName,
}: {
  children: React.ReactNode;
  name: InPortalName;
  outPortalName: OutPortalName;
}) {
  useRegisterInPortal({ name: name, intendedOut: outPortalName });
  const { outPortals } = useRequiredContext(PortalStoreContext);
  const [outPortalNode, setOutPortalNode] = useState<HTMLElement | null>(null);

  const tryToFindOutPortal = useCallback(() => {
    const ref = outPortals.get(outPortalName)?.ref || null;
    setOutPortalNode(ref?.current || null);
  }, [outPortalName, outPortals]);

  useLayoutEffect(() => {
    tryToFindOutPortal();
  }, [tryToFindOutPortal]);

  return <React.Fragment>{outPortalNode ? createPortal(children, outPortalNode) : null}</React.Fragment>;
});

interface OutPortalProps extends React.HTMLAttributes<HTMLDivElement> {
  name: OutPortalName;
}
/**
 * Renders a DOM node that can be targeted by an `InPortal`.
 */
export const OutPortal = observer(function OutPortal({ name, ...rest }: OutPortalProps) {
  const ref = useRef<HTMLDivElement>(null);
  useRegisterOutPortal({ name: name, ref: ref });

  return <div ref={ref} {...rest} />;
});
