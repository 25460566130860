import { initialize, type LDFlagSet, useFlags } from 'launchdarkly-react-client-sdk';

import { axiom } from '../../axiom';
import { orgStore } from '../../stores/OrgStore';
import { setFeatureFlags } from '../faro';
import { type ConvertKeysToCamelCase, convertKeysToCamelCase } from '../objects';

const EVENT_FLOW_PAGE_VARIANTS = ['prerelease', 'release', 'disabled', 'canary', undefined] as const;

type Flags = {
  cmdk: boolean;
  ['event-flow-page']: (typeof EVENT_FLOW_PAGE_VARIANTS)[number];
  ['pie-chart']: boolean;
  exemplars: boolean;
  ['slash-query']: boolean;
  ['live-toggle']: boolean;
  ['apl-only']: boolean;
};

function defaultFlags(): Flags {
  return {
    cmdk: false,
    ['event-flow-page']:
      EVENT_FLOW_PAGE_VARIANTS.find((variant) => variant === process.env.EVENT_FLOW_PAGE) || 'disabled',
    ['pie-chart']: false,
    exemplars: false,
    ['slash-query']: false,
    ['live-toggle']: false,
    ['apl-only']: false,
  };
}

type FlagsInReact = ConvertKeysToCamelCase<Flags>;

const flags: Flags = defaultFlags();
export const flagsInReact: FlagsInReact = convertKeysToCamelCase(flags);

type CombinedFeatureFlags = {
  ldFlags: LDFlagSet;
  mockedFlags: Record<string | symbol, any>;
};

/**
 * 🐉🐉🐉
 * The return of `useFlags` is a proxy that phones home to LaunchDarky every time one
 * of its properties is accessed. Previously, `useFeatureFlags` was spreading it,
 * which caused every property to get accessed every time `useFeatureFlags` was called.
 *
 * We only want it to phone home when a flag is actually checked for, so a new proxy is
 * created that allows us to get this behavior.
 */
const combinedHandler: ProxyHandler<CombinedFeatureFlags> = {
  get: (target, property: string) => {
    if (property in target.mockedFlags) {
      return target.mockedFlags[property];
    }
    if (property in target.ldFlags) {
      return target.ldFlags[property]; // this triggers the proxy get trap
    }

    return undefined;
  },
  ownKeys: (target) => {
    return Reflect.ownKeys(target.ldFlags).concat(Reflect.ownKeys(target.mockedFlags));
  },
  has: (target, property) => {
    return property in target.ldFlags || property in target.mockedFlags;
  },
};

const wrappedHandler: ProxyHandler<CombinedFeatureFlags> = {
  get: (target, property: string) => {
    const value = Reflect.get(target, property);

    setFeatureFlags({ [property]: value });

    return value;
  },
  ownKeys: (target) => {
    return Reflect.ownKeys(target);
  },
  has: (target, property) => {
    return Reflect.has(target, property);
  },
};

export const useFeatureFlags = () => {
  const ldFlags = useFlags();
  const mockedFlags = window.__mocks?.featureFlags ?? {};

  const combinedTarget: CombinedFeatureFlags = { ldFlags: ldFlags, mockedFlags: mockedFlags };
  const combinedProxy = new Proxy(
    new Proxy(combinedTarget, combinedHandler),
    wrappedHandler
  ) as unknown as FlagsInReact;

  return combinedProxy;
};

export const ldClient = initialize(axiom.launchDarklyClientId, {
  key: orgStore.activeOrgId,
  kind: 'organization',
  anonymous: false,
});

// use this outside of react
export const checkFeatureFlag = <T extends keyof Flags = keyof Flags>(flag: T): Flags[T] => {
  const defaultValue = flags[flag];

  // the flags are camelCased in the React SDK, but kebab-case in LD?
  const value = ldClient.variation(flag, defaultValue);

  return value;
};
