// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PDKriM3H43YrzLVqYie5 .axiom-modal-header {\n  background: var(--axi-default-bg);\n}\n.PDKriM3H43YrzLVqYie5 .axiom-modal-close-x {\n  color: var(--axi-body-color);\n  opacity: 0.8;\n}\n.PDKriM3H43YrzLVqYie5 .axiom-modal-close-x:hover {\n  opacity: 1;\n}\n.PDKriM3H43YrzLVqYie5 .axiom-modal-title {\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 30px;\n  color: var(--axi-header-color);\n}\n\n.SlzKbELmbXDTs9pvaRkX .axiom-modal-body {\n  padding: 0;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "PDKriM3H43YrzLVqYie5",
	"noBodyPadding": "SlzKbELmbXDTs9pvaRkX"
};
export default ___CSS_LOADER_EXPORT___;
