import { type Location, type LocationDescriptor } from 'history';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { type RouteComponentProps } from 'react-router-dom';

import { browserHistory } from './browserHistory';
import { cloneDeep } from './cloneDeep';
import { type ExtendedParsedQuery, stringifyWithQM } from './urls';
import { withRouter } from './withRouter';

const MAX_HISTORY = 100;

const locationHistory: Location[] = [];

const getPreviousLocation = () => {
  // The current location "should" be the actual last item in the array, so find the second to last to get "previous" item.
  return locationHistory.length > 1 ? locationHistory[locationHistory.length - 2] : undefined;
};

export const goBackIfPrevious = (
  newLocation?: LocationDescriptor | ((location: Location) => LocationDescriptor)
): boolean => {
  const previousLocation = getPreviousLocation();

  // Pop the previous location if it matches the newLocation or newLocation is falsey.
  // This avoids cluttering up the history.
  if (
    previousLocation?.pathname &&
    (!newLocation ||
      (typeof newLocation === 'string' && `${previousLocation.pathname}${previousLocation.search}` === newLocation))
  ) {
    // I tried using `browserHistory.goBack()` here and it doesn't seem to have the exact same behavior.
    window.history.back();
    locationHistory.pop();

    return true;
  }

  return false;
};

export const pushOrGoBack = (newPathnameWithSearch: string): boolean => {
  const wentBack = goBackIfPrevious(newPathnameWithSearch);
  if (!wentBack) {
    browserHistory.push(newPathnameWithSearch);
  }

  return wentBack;
};

export function currentLocationWithParams(newParams: Record<string, any>, oldParams?: ExtendedParsedQuery) {
  const params = cloneDeep(oldParams || {});

  Object.keys(newParams).forEach((name) => {
    const value = newParams[name];

    if (value) {
      params[name] = value;
    } else {
      delete params[name];
    }
  });

  const url = `${browserHistory.location.pathname}${stringifyWithQM(params)}`;

  return url;
}

export function persistParams(newParams: Record<string, any>, oldParams: ExtendedParsedQuery, replace?: boolean) {
  const url = currentLocationWithParams(newParams, oldParams);

  if (replace) {
    browserHistory.replace(url);
  } else {
    browserHistory.push(url);
  }
}

export function removeParams(
  params: ExtendedParsedQuery,
  keysToRemove: string[],
  replace?: boolean,
  pushOrBack?: boolean
) {
  const clonedParams = cloneDeep(params);

  keysToRemove.forEach((key) => {
    delete clonedParams[key];
  });

  const url = `${browserHistory.location.pathname}${stringifyWithQM(clonedParams)}`;

  if (replace) {
    browserHistory.replace(url);
  } else if (pushOrBack) {
    pushOrGoBack(url);
  } else {
    browserHistory.push(url);
  }
}

interface LocationHistoryProviderInnerProps extends Partial<RouteComponentProps<any>> {
  children?: React.ReactNode;
  watchOnlyPathname?: boolean;
}
class LocationHistoryProviderInner extends React.Component<LocationHistoryProviderInnerProps> {
  constructor(props: LocationHistoryProviderInnerProps) {
    super(props);

    locationHistory.push({
      ...props.location!,
    });
  }

  shouldComponentUpdate(nextProps: LocationHistoryProviderInnerProps) {
    // No need to adjust the history if the location didn't change.
    // In theory, this component could re-render for other reasons, so add this check here.
    // In my testing it seems like `key` will change if the browser modified
    // the history (even though everything else is the same, which is what we want)
    return !isEqual(this.props.location, nextProps.location);
  }

  componentDidUpdate(prevProps: LocationHistoryProviderInnerProps) {
    const { location } = this.props;

    // On Replace, remove the last location from the history.
    if (this.props.history && locationHistory.length) {
      if (this.props.history.action === 'REPLACE') {
        locationHistory.pop();
      } else if (this.props.history.action === 'POP') {
        // `action=POP` means user is navigating backward or forward.
        // If the last location in the history is the same as this, then it's likely backward and we should exit before
        // duplicating this location in the history.
        // 🐉🐉🐉
        // In rare cases this could be wrong and LocationHistory will get messed up. Not much we can do about it unfortunately since
        // browser limitation don't let us reliably determine back vs forward.
        // https://github.com/ReactTraining/react-router/issues/1200
        const previousLocation = getPreviousLocation();

        if (
          previousLocation?.pathname &&
          `${previousLocation.pathname}${previousLocation.search}` === `${location!.pathname}${location!.search}`
        ) {
          locationHistory.pop();

          return;
        }
      }
    }

    locationHistory.push({
      ...location!,
    });

    // Limit history to MAX_HISTORY items.
    if (locationHistory.length > MAX_HISTORY) {
      locationHistory.splice(MAX_HISTORY, locationHistory.length - MAX_HISTORY);
    }
  }

  render() {
    return this.props.children;
  }
}

export const LocationHistoryProvider = withRouter(LocationHistoryProviderInner);
