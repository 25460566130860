import { useState, useCallback, useRef } from 'react';

export const useAttachmentUpload = ({ setIsAccordionOpen }: { setIsAccordionOpen: (value: string) => void }) => {
  const [attachments, setAttachments] = useState<
    {
      file: File;
      preview?: string;
    }[]
  >([]);
  const [isInvalidFiles, setIsInvalidFiles] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [filesExceedMaximumBytes, setFilesExceedMaximumBytes] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const MAX_FILE_SIZE = 6 * 1024 * 1024; // 6MB in bytes

  const isValidFileType = (file: File) => ['image/png', 'image/jpeg', 'image/webp'].includes(file.type);

  const formatSize = (bytes: number) => {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + sizes[i];
  };

  const updateTotalFileSize = useCallback(
    (files: File[]) => {
      const newTotalSize = files.reduce((total, file) => total + file.size, 0);

      setTotalFileSize((prevTotal) => {
        const updatedTotal = prevTotal + newTotalSize;
        setFilesExceedMaximumBytes(updatedTotal > MAX_FILE_SIZE);

        return updatedTotal;
      });
    },
    [MAX_FILE_SIZE]
  );

  const checkDraggedFiles = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    const items = event.dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === 'file') {
        const file = item.getAsFile();
        if (file && !isValidFileType(file)) {
          setIsInvalidFiles(true);

          return;
        }
      }
    }
    setIsInvalidFiles(false);
  }, []);

  const handleFiles = useCallback(
    (files: File[]) => {
      const validFiles = files.filter(isValidFileType);
      const hasInvalidFiles = files.length !== validFiles.length;

      setIsInvalidFiles(hasInvalidFiles);
      updateTotalFileSize(validFiles);

      validFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachments((prev) => [...prev, { file: file, preview: reader.result as string }]);
        };
        reader.readAsDataURL(file);
      });
    },
    [updateTotalFileSize]
  );

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      handleFiles(Array.from(files));
    }
  };

  const handleDragEnter = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(true);
      checkDraggedFiles(event);
    },
    [checkDraggedFiles]
  );

  const handleDragLeave = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(false);
      setIsInvalidFiles(false);
    },
    [setIsDraggingOver]
  );

  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(true);
      checkDraggedFiles(event);
    },
    [checkDraggedFiles]
  );

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIsDraggingOver(false);
      const files = event.dataTransfer.files;
      handleFiles(Array.from(files));
    },
    [handleFiles]
  );

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      if (event.clipboardData) {
        const items = event.clipboardData.items;
        const files: File[] = [];
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          if (item.type.indexOf('image') !== -1) {
            const file = item.getAsFile();
            if (file) {
              files.push(file);
            }
          }
        }
        if (files.length > 0) {
          event.preventDefault();
          setIsAccordionOpen('attachment-accordion');
          handleFiles(files);
        }
      }
    },
    [handleFiles, setIsAccordionOpen]
  );

  const removeAttachment = (index: number) => {
    setAttachments((prev) => {
      const removedFile = prev[index];
      const updatedAttachments = prev.filter((_, i) => i !== index);
      setTotalFileSize((prevTotal) => {
        const updatedTotal = prevTotal - removedFile.file.size;

        setFilesExceedMaximumBytes(updatedTotal > MAX_FILE_SIZE);

        return updatedTotal;
      });

      return updatedAttachments;
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const clearInvalidFilesFlag = () => {
    setIsInvalidFiles(false);
  };

  return {
    attachments: attachments,
    setAttachments: setAttachments,
    isInvalidFiles: isInvalidFiles,
    isDraggingOver: isDraggingOver,
    formattedTotalFileSize: formatSize(totalFileSize),
    filesExceedMaximumBytes: filesExceedMaximumBytes,
    fileInputRef: fileInputRef,
    handleFiles: handleFiles,
    handlePaste: handlePaste,
    handleFileChange: handleFileChange,
    handleDragEnter: handleDragEnter,
    handleDragLeave: handleDragLeave,
    handleDragOver: handleDragOver,
    handleDrop: handleDrop,
    removeAttachment: removeAttachment,
    clearInvalidFilesFlag: clearInvalidFilesFlag,
  };
};
