// cSpell:ignore localstorage
// localStorageAvailable from safe-localstorage 1.0.1
// Project: https://github.com/andrewdushane/safe-localstorage/blob/a18f5e131a0f85b0f1ab242194f8cd947fda5d78/src.js

const localStorageAvailable = () => {
  const inWindow = typeof window.localStorage === 'object' && typeof window.localStorage.setItem === 'function';
  if (!inWindow) {
    return false;
  }
  // Safari private mode has localStorage in the window, but throws when `setItem` is called
  const key = 'safeLocalStorageTest';
  try {
    window.localStorage.setItem(key, 'succeeds');
    window.localStorage.removeItem(key);

    return true;
  } catch (e) {
    console.warn('localStorage is not available');

    return false;
  }
};

class MemoryLocalStorage {
  private store: Record<string, string> = {};

  public get length() {
    return Object.keys(this.store).length;
  }

  public clear() {
    this.store = {};
  }
  public getItem(key: string) {
    return this.store[key];
  }
  public key(index: number) {
    return Object.keys(this.store)[index];
  }
  public setItem(key: string, value: string) {
    this.store[key] = value;
  }
  public removeItem(key: string) {
    delete this.store[key];
  }
}

export const safeLocalStorage = localStorageAvailable() ? window.localStorage : new MemoryLocalStorage();
