export function focusSomething(domElement: HTMLElement, querySelectors: string[]) {
  // clone the array.
  const selectors = [...querySelectors];
  const querySelector = selectors.shift();

  if (querySelector) {
    const firstElemDom = domElement.querySelector(querySelector);
    if (firstElemDom) {
      (firstElemDom as HTMLElement).focus();
    } else {
      focusSomething(domElement, selectors);
    }
  }
}
