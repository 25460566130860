

module.exports = function loaderpl(locale, cb) {
  require.ensure([
      'moment/locale/pl'
      , 'numbro/dist/languages/pl-PL.min'
      , 'sugar-date/locales/pl'
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/pl');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const numbro = require('numbro');
      numbro.registerLanguage(require('numbro/dist/languages/pl-PL.min'));
      numbro.setLanguage(locale);

    const Sugar = require('sugar-date');
      require('sugar-date/locales/pl');
      try { Sugar.Date.setLocale(locale); } catch(e) { try { Sugar.Date.setLocale(locale.split('-')[0].toLowerCase()); } catch(ee) { } }

    cb();
  }, 'locale-pl');
};

