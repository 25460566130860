// fas - Solid (default)

import { library } from '@fortawesome/fontawesome-svg-core';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons/faAlarmSnooze';
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause';
import { faSquare } from '@fortawesome/pro-solid-svg-icons/faSquare';
import { faTableColumns } from '@fortawesome/pro-solid-svg-icons/faTableColumns';

library.add(
  faAlarmSnooze,
  faEnvelope,
  faBolt,
  faCheckCircle,
  faCaretDown,
  faCircle,
  faExclamationTriangle,
  faPause,
  faTableColumns,
  faSquare
);
