// fal - Regular

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';

library.add(faBookmark, faCircle, faCheckCircle, faChevronDown, faChevronRight);
