export interface NamedItem {
  name: string;
}

export const sortNamedItem = (aa: NamedItem, bb: NamedItem) => {
  if (aa.name < bb.name) {
    return -1;
  }
  if (aa.name > bb.name) {
    return 1;
  }

  return 0;
};

export const sortNamedItemCaseInsensitive = (aa: NamedItem, bb: NamedItem) => {
  if (aa.name.toLowerCase() < bb.name.toLowerCase()) {
    return -1;
  }
  if (aa.name.toLowerCase() > bb.name.toLowerCase()) {
    return 1;
  }

  return 0;
};
