import React from 'react';

import { cn } from '../util/styles';

import styles from './TextArea.module.scss';

type InputSize = 'default' | 'small';

export interface TextAreaProps
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'size' | 'prefix' | 'wrap'> {
  hasError?: boolean;
  size?: InputSize;
  expand?: boolean;
  innerRef?: React.LegacyRef<HTMLTextAreaElement>;
  autoRows?: boolean;
  rowsMax?: number;
}

export const TextArea = ({
  className,
  disabled = false,
  expand,
  hasError,
  innerRef,
  size,
  autoRows,
  rowsMax,
  rows,
  ...passthrough
}: TextAreaProps) => {
  return (
    <textarea
      ref={innerRef}
      className={cn(
        'input', // Needed for various CSS and Menu auto focus.
        styles.input,
        styles[`size-${size || 'default'}`],
        { [styles.error]: hasError, [styles.expand]: expand },
        className
      )}
      disabled={disabled}
      rows={
        autoRows
          ? Math.max(
              rows ?? 1,
              Math.min(rowsMax ?? Infinity, (String(passthrough.value).match(/\n/g) ?? [])?.length + 1)
            )
          : rows
      }
      {...passthrough}
    />
  );
};
