// cSpell:ignore Duotone
import { type IconName as FontAwesomeIconName, type IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { forwardRef } from 'react';

import { customIconNames, customIcon, type CustomIcon } from '../assets/Icons-axe_ui_lib';
import { cn } from '../util/styles';

import styles from './Icon.module.scss';

export type IconName = CustomIcon | FontAwesomeIconName;

export type IconColor =
  | 'blue'
  | 'default'
  | 'green'
  | 'muted'
  | 'orange'
  | 'orange-dark'
  | 'red'
  | 'teal'
  | 'purple'
  | 'ok'
  | 'disabled'
  | 'error'
  | 'warning'
  | 'white';

/**
 * fas: Solid (default)
 * far: Regular
 * fal: Light
 * fad: Duotone
 * fab: Brand
 */
export type IconVariant = IconPrefix;

export interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
  color?: IconColor;
  name?: IconName;
  variant?: IconVariant;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(function Icon(
  { className, color, name, variant, ...passthrough },
  ref
) {
  if (!name) {
    return null;
  }

  if (customIconNames.some((icon) => icon === name)) {
    // @ts-expect-error: customIcon is CustomIcon & { [key: string]: ReactElement }
    const CustomIcon = customIcon[name];

    return (
      <CustomIcon ref={ref} className={cn('icon', color && styles[`color-${color}`], className)} {...passthrough} />
    );
  }

  return (
    <FontAwesomeIcon
      ref={ref}
      icon={[
        variant || 'fal',
        name as FontAwesomeIconName, // if it exists and haven't returned yet, it must be a FontAwesomeIconName
      ]}
      className={cn('icon', color && styles[`color-${color}`], className)}
      {...passthrough}
    />
  );
});
