import {
  type AsyncStorage,
  type PersistedQuery,
  experimental_createPersister,
} from '@tanstack/query-persist-client-core';
import { get, set, del, clear, createStore, type UseStore } from 'idb-keyval';

const AXIOM_DB_NAME = 'axiom-frontend';
// 🚨 change this to invalidate all data a user might have
// this is useful if the behaviour of some endpoint or query changes
const AXIOM_DB_VERSION = 'v1';

// eslint ignoring because `newIdbStorage` is just copy pasted from tanstack query github
/* eslint-disable no-return-await */
function newIdbStorage(idbStore: UseStore): AsyncStorage<PersistedQuery> {
  return {
    getItem: async (key) => await get(key, idbStore),
    setItem: async (key, value) => await set(key, value, idbStore),
    removeItem: async (key) => await del(key, idbStore),
  };
}

const idbStore = createStore(AXIOM_DB_NAME, AXIOM_DB_VERSION);

export const clearIndexedDB = async () => {
  await clear(idbStore);
};

type IndexedDbPersistOptions = {
  gcTime?: number;
};

export const indexedDbPersistOptions = (opts?: IndexedDbPersistOptions) => ({
  gcTime: opts?.gcTime ?? 1000 * 60 * 60 * 24 * 30, // 30 days,
  persister: experimental_createPersister<PersistedQuery>({
    storage: newIdbStorage(idbStore),
    maxAge: 1000 * 60 * 60 * 24 * 30, // 30 days,
    serialize: (persistedQuery) => persistedQuery,
    deserialize: (cached) => cached,
  }),
});
