

module.exports = function loaderen(locale, cb) {
  require.ensure([
      'moment/locale/en-au', 'moment/locale/en-ca', 'moment/locale/en-gb', 'moment/locale/en-ie', 'moment/locale/en-il', 'moment/locale/en-nz'
      , 'numbro/dist/languages/en-AU.min', 'numbro/dist/languages/en-GB.min', 'numbro/dist/languages/en-IE.min', 'numbro/dist/languages/en-NZ.min', 'numbro/dist/languages/en-ZA.min'
      
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/en-au');
      require('moment/locale/en-ca');
      require('moment/locale/en-gb');
      require('moment/locale/en-ie');
      require('moment/locale/en-il');
      require('moment/locale/en-nz');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const numbro = require('numbro');
      numbro.registerLanguage(require('numbro/dist/languages/en-AU.min'));
      numbro.registerLanguage(require('numbro/dist/languages/en-GB.min'));
      numbro.registerLanguage(require('numbro/dist/languages/en-IE.min'));
      numbro.registerLanguage(require('numbro/dist/languages/en-NZ.min'));
      numbro.registerLanguage(require('numbro/dist/languages/en-ZA.min'));
      numbro.setLanguage(locale);

    
    cb();
  }, 'locale-en');
};

