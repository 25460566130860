import {
  type Org as ApiOrg,
  type Storage as ApiStorage,
  type License as ApiLicense,
  type UserDetailsRole,
  type Membership,
  type MonitorHistory as ApiMonitorHistory,
} from '../shared/dash';

export const OwnerRole = 'owner';
export const AdminRole = 'admin';
export const UserRole = 'user';
export const ReadOnlyRole = 'read-only';
export const NoneRole = 'none';
export const Roles = [OwnerRole, AdminRole, UserRole, ReadOnlyRole, NoneRole] as const;
export const AxiomOwnerRole = 'axiom-owner';
export const AxiomAdminRole = 'axiom-admin';
export const AxiomUserRole = 'axiom-user';
export const AxiomReadOnlyRole = 'axiom-read-only';
export const AxiomNoneRole = 'axiom-none';
export const AxiomRoles = [AxiomOwnerRole, AxiomAdminRole, AxiomUserRole, AxiomReadOnlyRole, AxiomNoneRole];

interface AxiomFeatures {
  byos?: boolean;
  endpointsDatadog?: boolean;
  endpointsHoneycomb?: boolean;
  endpointsLoki?: boolean;
  endpointsSplunkevents?: boolean;
  endpointsSecureSyslog?: boolean;
  notifiersEmail?: boolean;
  notifiersDiscord?: boolean;
  notifiersDiscordWebhook?: boolean;
  notifiersMicrosoftTeams?: boolean;
  notifiersOpsgenie?: boolean;
  notifiersPagerduty?: boolean;
  notifiersSlack?: boolean;
  netlifyIntegration?: boolean;
  cloudflareIntegration?: boolean;
  notifiersWebhook?: boolean;
  traces?: boolean;
  tabularFormat?: boolean;
  assistant?: boolean;
  datadogMigrationAssistant?: boolean;
  libraries?: boolean;
}

export type LicenseTier = ApiLicense['tier'];

export type PaymentStatusType = Org['paymentStatus'];

export interface License extends Omit<ApiLicense, 'features'> {
  features: AxiomFeatures;
  tier: LicenseTier;
}

export interface Org extends Omit<ApiOrg, 'role' | 'license'> {
  role: string;
  license: License;
  plan: LicenseTier;
}

export type RoleCapabilityAction = '*' | 'create' | 'read' | 'update' | 'delete';
type RoleCapabilityAccess = 'allow' | 'deny';
export type Capability = Record<
  string,
  {
    Actions: RoleCapabilityAction[];
    Access: RoleCapabilityAccess;
  }
>;

// User is used when returning multiple users and includes the role and permissions.
export type User = {
  id: string;
  email: string;
  name: string;
  role: UserDetailsRole;
  dataset_permissions?: any;
  org_permissions?: Capability;
  permissions: string[];
  role_membership?: Membership[];
  group_membership?: Membership[];
  defaultDashboards?: Record<string, string>;
};

export interface Storage extends ApiStorage {
  id?: string;
  name?: string;
  enabled?: boolean;
}

export interface MonitorHistory extends Omit<ApiMonitorHistory, 'data'> {
  data?: Record<string, any[][]>;
}

export type MonitorHistoryState = Record<string, HistoryStateEvent[]>;

export type HistoryStateEvent = {
  notified: boolean;
  matching_values: any[];
  time: string;
  status: 'open' | 'closed';
  tooltip?: string;
  disabled?: boolean;
  error?: boolean;
};

export type MonitorGroupStates = 'normal' | 'triggered' | 'disabled' | 'errored';
export type MonitorStatusGroup = {
  normal: string[];
  triggered: string[];
  disabled: string[];
  errored: string[];
};
