import configureCloneDeep from 'rfdc';

export const cloneDeep = configureCloneDeep({
  /**
   * This is ~15x faster than lodash's cloneDeep, and gives the same results.
   *
   * There might be some edge cases where we need to clone objects with circular references.
   *
   * In that case, please export a separate function!
   */
  circles: false,
  proto: false,
  constructorHandlers: [],
});
