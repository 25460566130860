import { FaroErrorBoundary } from '@grafana/faro-react';
import React from 'react';
import { TrackJS } from 'trackjs';

import { axiom } from '../axiom';

import { FriendlyError } from './FriendlyError';

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const onError = (error: Error) => {
    // If this is a `ChunkLoadError` then do a server-side reload.
    if (error.name.includes('ChunkLoadError')) {
      window.location.reload();
    } else {
      TrackJS.track(error);
    }
  };

  if (axiom.enableJSMonitoring) {
    return (
      <FaroErrorBoundary
        fallback={(faroError, resetBoundary) => <FriendlyError error={faroError} resetBoundary={resetBoundary} />}
        onError={onError}
      >
        {children}
      </FaroErrorBoundary>
    );
  }

  return <ErrorBoundaryInternal>{children}</ErrorBoundaryInternal>;
};

type ErrorBoundaryState = {
  error?: any;
};

class ErrorBoundaryInternal extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // If this is a `ChunkLoadError` then do a server-side reload.
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    } else {
      TrackJS.track(error);
    }
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <FriendlyError error={error} resetBoundary={() => this.setState({ error: undefined })} />;
    }

    return this.props.children;
  }
}
