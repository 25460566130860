import React, { useState } from 'react';

import { isEnvDev } from '../util/env';
import { errorToString } from '../util/error-to-string';

import { Button } from './Button';
import { ContactSupportForm } from './ContactSupportForm';

type FriendlyErrorProps = {
  error: Error | string | undefined;
  resetBoundary: () => void;
};

export const FriendlyError = ({ error, resetBoundary }: FriendlyErrorProps) => {
  const errorMessage = errorToString(error).replace('TrackJS Caught: ', '');

  const [showCustomerSupportForm, setShowCustomerSupportForm] = useState(false);

  return (
    <div className="relative flex flex-col gap-2 p-2">
      <div className="absolute right-2 top-2">
        <Button type="axi-secondary" icon="refresh" onClick={() => void resetBoundary()} />
      </div>
      <div className="text-xl">Something went wrong</div>
      {isEnvDev() && <div className="text-red">Error: {errorMessage}</div>}
      <div>
        <Button type="axi-secondary" onClick={() => setShowCustomerSupportForm(true)}>
          Contact support
        </Button>
      </div>
      <ContactSupportForm
        type="modal"
        initialSupportType="Problem"
        setShowModal={setShowCustomerSupportForm}
        showModal={showCustomerSupportForm}
        additionalDetails={errorMessage}
      />
    </div>
  );
};
