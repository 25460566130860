// cSpell:ignore csrf xsrf

import axios from 'axios';

export function initAxios() {
  // configure axios defaults for every request
  axios.defaults.timeout = 59 * 1000; // milliseconds
  axios.defaults.xsrfCookieName = '_csrf';
  axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
}
