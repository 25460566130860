// Expose these is useful? Uber early abstraction, but I wanna have this things centralized here
export const isTimeoutError = (error: string) => error.startsWith('timeout of');

// Gotta expose both `isTimeoutError` and `errorMessages` to keep the same implementation in StoreBase.ts
export const errorMessages = {
  timeout: 'Query is taking too long. Try a smaller time range.',
} as const;

/**
 * @returns A pretty error message, falls back to the original error if it's not a handled case
 */
export const prettyErrorMessage = (error: string) => {
  // If we end up with ts-pattern or something we'll need to revisit this
  if (isTimeoutError(error)) {
    return errorMessages.timeout;
  }

  return error;
};
