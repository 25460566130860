import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CSSTransition } from 'react-transition-group';

import { cn } from '../util/styles';

import { Icon } from './Icon';

interface CopyTextProps extends React.HTMLAttributes<HTMLSpanElement> {
  text: string;
}

export const CopyText = ({ text, className, ...passthrough }: CopyTextProps) => {
  const [animating, setAnimating] = useState(false);

  const onCopy = () => {
    setAnimating(true);
  };

  const onEntered = () => {
    setAnimating(false);
  };

  return (
    <CopyToClipboard text={text} onCopy={onCopy}>
      <span
        role="button"
        className={cn('pointer text-muted hover:bg-element-hover [&>*:last-child]:ml-2', className)}
        {...passthrough}
      >
        {text}

        {animating ? (
          <CSSTransition
            in={true}
            appear={true}
            classNames={{ appearActive: 'opacity-100' }}
            // Set to how long you'd like to display the success check mark.
            timeout={1500}
            onEntered={onEntered}
          >
            <Icon
              className="opacity-0 transition-opacity ease-in-out icon-green [transition-duration:250ms]"
              name="check"
            />
          </CSSTransition>
        ) : (
          <Icon name="copy" size="sm" />
        )}
      </span>
    </CopyToClipboard>
  );
};
