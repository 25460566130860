// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FjQc1XaR5pbV05kRgqMg {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  border-radius: 4px;\n}\n\n.fotDOKFnG5q15asUGuVq {\n  flex: 1 1 auto;\n  height: 100%;\n  overflow-y: auto;\n  padding: 16px;\n}\n.fotDOKFnG5q15asUGuVq .form-item:last-child {\n  margin-bottom: 0;\n}\n\n.wUChp7Cop4gF74gXFnRs {\n  display: flex;\n  justify-content: space-between;\n  flex: 0 0 auto;\n  gap: 24px;\n  padding: 16px;\n  min-height: 64px;\n  width: 100%;\n  z-index: 11;\n  border-top: 1px solid var(--axi-default-border);\n  background: var(--axi-subtle-bg);\n  border-bottom-right-radius: 4px;\n  border-bottom-left-radius: 4px;\n}\n\n.Xo5p4Sk81GrBUaNsqmMG {\n  width: 100%;\n  max-width: 160px;\n}\n\n.g0BY4Dk9i5ld2WWNhq4V .Xo5p4Sk81GrBUaNsqmMG {\n  max-width: 120px;\n}\n\n.TaGXNaaRuyvUHRVOgcHB .fotDOKFnG5q15asUGuVq {\n  padding: 16px 20px;\n}\n.TaGXNaaRuyvUHRVOgcHB .wUChp7Cop4gF74gXFnRs {\n  border-top: none;\n  padding: 16px 20px;\n}\n.TaGXNaaRuyvUHRVOgcHB .wUChp7Cop4gF74gXFnRs button {\n  min-width: 124px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "FjQc1XaR5pbV05kRgqMg",
	"content": "fotDOKFnG5q15asUGuVq",
	"buttons": "wUChp7Cop4gF74gXFnRs",
	"buttonWrapper": "Xo5p4Sk81GrBUaNsqmMG",
	"buttons-small": "g0BY4Dk9i5ld2WWNhq4V",
	"buttonsSmall": "g0BY4Dk9i5ld2WWNhq4V",
	"modalMode": "TaGXNaaRuyvUHRVOgcHB"
};
export default ___CSS_LOADER_EXPORT___;
