import { FaroRoute } from '@grafana/faro-react';
import React from 'react';
import { Route as RouterRoute, type RouteProps } from 'react-router-dom';

import { axiom } from '../axiom';

// Newer Omit type: as the previous one is being exported, removing it would be a breaking change
type OmitNative<T, K extends string | number | symbol> = { [P in Exclude<keyof T, K>]: T[P] };

type CustomRouteProps<T extends {} = {}, Path extends string = string> = RouteProps<Path> &
  OmitNative<T, keyof RouteProps>;

export const Route = <T extends {} = {}, Path extends string = string>(props: CustomRouteProps<T, Path>) => {
  if (!axiom.enableJSMonitoring) {
    return <RouterRoute {...props} />;
  }

  return <FaroRoute {...props} />;
};
