import React from 'react';

import { cn } from '../util/styles';

import styles from './Spin.module.scss';

interface SpinProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Spin = ({ className, ...passthrough }: SpinProps) => {
  return (
    <div className={cn(styles.spin, className)} {...passthrough}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
