import { action, observable, makeObservable } from 'mobx';
import moment from 'moment-timezone';
import { createContext } from 'react';

import { dashApi } from '../shared/dash';
import { axiomDebug } from '../util/axiomDebug';
import { browserSupportsTimezone, defaultTimezone } from '../util/dates';
import { safeLocalStorage } from '../util/safeLocalStorage';

import { SimpleOperationsTracker, StoreBase } from './StoreBase';
import { userStatusStore } from './UserStatusStore';

const LOCAL_STORAGE_KEY = 'userProfile';

class UserProfileStore extends StoreBase {
  public updateNameOps = new SimpleOperationsTracker(this, 'updateName');

  @observable
  public timeZone: string = defaultTimezone;

  constructor() {
    super();

    makeObservable(this);

    try {
      const userProfile = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!);
      if (userProfile) {
        if (browserSupportsTimezone(userProfile.timeZone)) {
          this.timeZone = userProfile.timeZone;
        }
      }
    } catch (error) {
      console.warn('Failed to load user profile settings from localStorage.');
    }

    // we need to do this so Ant doesn't format moment dates as the browser's local time.
    moment.tz.setDefault(this.timeZone);
  }

  @action.bound
  public updateName(name: string): void {
    if (!userStatusStore.user?.id) {
      // Shouldn't happen.
      throw new Error('userStatusStore.user or userStatusStore.user.id is not defined.');
    }

    // Bail out if nothing has changed.
    if (userStatusStore.user.name === name) {
      return;
    }

    // Optimistically apply the update.
    const originalName = userStatusStore.user.name;
    userStatusStore.user.name = name;

    void this.operate(
      this.updateNameOps.operation,
      dashApi.updateUser(userStatusStore.user.id, {
        name: name,
      }),
      undefined,
      true,
      () => {
        if (!userStatusStore.user) {
          // Shouldn't happen.
          throw new Error('userStatusStore.user is not defined.');
        }

        // Rollback changes on error.
        userStatusStore.user.name = originalName;
      }
    );
  }

  @action.bound
  public updateTimeZone(timeZone: string): void {
    const newTimeZone = timeZone || defaultTimezone;
    if (browserSupportsTimezone(newTimeZone)) {
      this.timeZone = newTimeZone;
      moment.tz.setDefault(this.timeZone);
    }
    this.saveUserProfile();
  }

  private saveUserProfile() {
    safeLocalStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        timeZone: this.timeZone,
      })
    );
  }
}

export const UserProfileStoreContext = createContext<UserProfileStore | null>(null);
UserProfileStoreContext.displayName = 'UserProfileStoreContext';

// We really only need one global userProfileStore so create that instead of doing a Provider.
// This will make it easier to use in other Stores.
export const userProfileStore = new UserProfileStore();

axiomDebug(userProfileStore);
