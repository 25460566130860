import isEqual from 'lodash/isEqual';
import { type SessionContextValue, useSession } from 'next-auth/react';
import React from 'react';

import { browserHistory } from './browserHistory';
import { omit } from './omit';

type UseSessionProps = {
  required?: boolean;
  children(session: SessionContextValue): React.ReactNode;
};

export const UseSession = ({ children }: UseSessionProps) => {
  const session = useSession();

  return <SessionChangeDetector session={session}>{children(session)}</SessionChangeDetector>;
};

interface SessionChangeDetectorProps {
  children: React.ReactNode;
  session: SessionContextValue;
}

class SessionChangeDetector extends React.Component<SessionChangeDetectorProps> {
  componentDidUpdate(prevProps: Readonly<SessionChangeDetectorProps>): void {
    // Redirect to the callbackUrl if it exists.
    if (prevProps.session.status === 'unauthenticated' && this.props.session.status === 'authenticated') {
      const callbackUrl = new URLSearchParams(window.location.search).get('callbackUrl');
      if (callbackUrl) {
        browserHistory.push(callbackUrl);
      }
    }
  }

  shouldComponentUpdate(nextProps: Readonly<SessionChangeDetectorProps>): boolean {
    // This prevents regaining window focus (which triggers `useSession` to update) from causing
    // the `MainRouter` to re-render, which causes components like
    // `Authed` to reinitialize and lose all their state.
    // https://linear.app/axiom/issue/FE-805/switching-tabs-in-safari-clears-data-explorer-dashboard-editor
    // 🐉🐉🐉
    // So far the only prop that seems to change is `session.data.expires` which we can safely ignore.
    // In the future something with `user` or a new prop could possibly cause issues.
    return (
      !isEqual(this.props.session.status, nextProps.session.status) ||
      !isEqual(omit(this.props.session.data || {}, 'expires'), omit(nextProps.session.data || {}, 'expires'))
    );
  }

  render() {
    return this.props.children;
  }
}
