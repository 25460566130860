import hoistStatics from 'hoist-non-react-statics';
import React from 'react';
import { type RouteComponentProps } from 'react-router-dom';

import { Route } from '../routes/Route';

export function withRouter<T extends React.ComponentClass>(Component: T): T {
  const Wrapper = (props: RouteComponentProps) => {
    const { wrappedComponentRef, ...remainingProps } = props as any;

    return (
      <Route
        children={(routeComponentProps) => (
          <Component {...remainingProps} {...routeComponentProps} ref={wrappedComponentRef} />
        )}
      />
    );
  };

  const WrapperAny = Wrapper as any;

  WrapperAny.displayName = `withRouter(${Component.displayName || Component.name})`;
  WrapperAny.WrappedComponent = Component;

  // this typing stuff is probably wrong and the `as any` is a hack, but things seem to work.
  return hoistStatics(Wrapper as any, Component) as T;
}
