type WindowWithAnalytics = typeof window & {
  analytics?: {
    user?: () => { id?: () => string };
    identify: (id: string, data: { name?: string; email?: string }) => void;
    page: (
      a: undefined,
      b: undefined,
      options: { path: string; referrer: string; search: string; title: string; url: string }
    ) => void;
  };
};

const getWindowWithAnalytics = (window: (typeof globalThis)['window']): WindowWithAnalytics => window;

export function getTrackingId(): string | undefined {
  return getWindowWithAnalytics(window).analytics?.user?.().id?.();
}

export function identifyUser(user: { id: string; name?: string; email?: string }): void {
  /**
   * The backend is currently returning inconsistent data shape for `/internal/user`
   * can either have `email: string` (dev) or `emails: Array<string>` (prod)
   */
  const TODO_REMOVE_THIS_AFTER_FIXED_ON_BACKEND_userEmail = user.email ?? (user as any).emails?.[0];

  getWindowWithAnalytics(window).analytics?.identify(user.id, {
    name: user.name,
    email: TODO_REMOVE_THIS_AFTER_FIXED_ON_BACKEND_userEmail,
  });
}

export function trackPage(): void {
  // Manually track the location since Segment doesn't fire synchronously and will use the wrong location for redirected Urls.
  getWindowWithAnalytics(window).analytics?.page(undefined, undefined, {
    path: window.location.pathname,
    referrer: window.document.referrer,
    search: window.location.search,
    // title: window.document.title,
    title: 'Axiom', // Title hasn't been updated yet, so just default to "Axiom"; otherwise it will be the last page's title.
    url: window.location.href,
  });
}
