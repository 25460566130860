import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { useState } from 'react';

import { notification } from '../components/Notification';
import { createTRPCReactClient, trpc } from '../util/trpc';

/**
 * This creates the providers for both Tanstack Query and tRPC
 * We are using tRPC with its Tanstack Query integration for some endpoints, and "vanilla" Tanstack Query for others
 * Both share the same QueryClient
 */

const E2E_TESTING = process.env.E2E_TESTING === 'true';
const PLAYWRIGHT_TESTING = process.env.PLAYWRIGHT_TESTING === 'true';

export const NOT_TESTING = !E2E_TESTING && !PLAYWRIGHT_TESTING;
const IS_DEV = process.env.NODE_ENV === 'development';

export const SHOW_TANSTACK_DEVTOOLS = IS_DEV && NOT_TESTING;

/**
 * We need to define our QueryClient here so that we can use it
 * outside of the React tree, e.g. in MobX stores.
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      /**
       * By default Tanstack Query retries 4 times before considering a request failed.
       * That can take a while, so we reduce it to 1 retry.
       * Could also set this to 0 to disable retries completely.
       */
      retry: 0,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query?.meta?.toast) {
        notification.error(
          {
            message: 'Error',
            description: `${error.message}`,
          },
          {
            autoClose: false,
            toastId: error.name,
          }
        );
      }
    },
  }),
});

/**
 * This allows us to use both Tanstack Query's `useQuery`, `useMutation`, `useQueryClient`, etc.
 * and tRPC's `trpc.someRouter.someProcedure.query()` etc. in our components and hooks
 */
export function TanstackQueryTrpcProvider({ children }: { children: React.ReactNode }) {
  const [trpcClient] = useState(createTRPCReactClient);

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <React.Fragment>{children}</React.Fragment>
        {SHOW_TANSTACK_DEVTOOLS ? <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" /> : null}{' '}
      </QueryClientProvider>
    </trpc.Provider>
  );
}
