import React from 'react';

import { cn } from '../util/styles';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: React.ReactNode;
  hasError?: boolean;
  indeterminate?: boolean;
}

export const Checkbox = ({ className, hasError, label, indeterminate, ...passthrough }: CheckboxProps) => {
  return (
    <label className={cn(styles.label, { [styles.error]: hasError }, className)}>
      {label}
      {/* Global `input` class added for Menu.tsx sake. */}
      {/* Seems to be a false positive with this a11y check */}
      <input type="checkbox" className="input" {...passthrough} />
      <span className={cn(styles.check, { [styles.indeterminate]: indeterminate })} />
    </label>
  );
};
