import classNames from 'classnames';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { type FieldError } from 'react-hook-form';

import { type RadixClassNameTypeHelper } from '../primitives/temporaryRadixTypeHelper';

export const FormItemHelper = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement> &
    RadixClassNameTypeHelper & {
      helperText: React.ReactNode;
      hasError?: boolean; // manually add error for situations where we can't rely on react-hook-form
      error?: FieldError | string;
      formMessageId?: string;
    }
>((props, ref) => {
  const { className, hasError, helperText, formMessageId, error, ...passthrough } = props;

  const text = error ? (typeof error === 'string' ? error : String(error?.message)) : helperText ?? '';

  return (
    <AnimateHeight duration={150} height={text ? 'auto' : 0}>
      <p
        ref={ref}
        id={formMessageId}
        className={classNames(
          'text-subtle mb-0 text-sm leading-6 [&_p:last-child]:mb-0',
          (error || hasError) && 'text-red',
          className
        )}
        {...passthrough}
      >
        {text ?? helperText ?? ''}
      </p>
    </AnimateHeight>
  );
});

FormItemHelper.displayName = 'FormItemHelper';
