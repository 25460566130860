

module.exports = function loadertr(locale, cb) {
  require.ensure([
      'moment/locale/tr'
      , 'numbro/dist/languages/tr-TR.min'
      
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/tr');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const numbro = require('numbro');
      numbro.registerLanguage(require('numbro/dist/languages/tr-TR.min'));
      numbro.setLanguage(locale);

    
    cb();
  }, 'locale-tr');
};

