import { configure } from 'mobx';

export function initMobx() {
  // require that `action` is used when modifying observables.
  configure({
    enforceActions: 'observed',
    // Recommended linting options below but are commented out
    // See: https://mobx.js.org/configuration.html#linting-options
    //
    // FUTURE: At this time, some issues are only detectable at run time and it looks like we
    // have many non-idiomatic usages to address which will require a bit of work.
    // computedRequiresReaction: true,
    // observableRequiresReaction: true,
    // reactionRequiresObservable: true,
  });
}
