import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';

import { safeLocalStorage } from '../util/safeLocalStorage';

import { StoreBase } from './StoreBase';

const LOCAL_STORAGE_KEY = 'userPreferences';

// TODO: @cje - move this into postgres?
type UserPreferences = {
  showSidebarNullValues?: boolean;
};

export class UserPreferencesStore extends StoreBase {
  @observable
  public showSidebarNullValues = false;

  constructor() {
    super();

    makeObservable(this);

    try {
      const userPreferences = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY)!) as UserPreferences;
      if (userPreferences) {
        this.showSidebarNullValues = userPreferences.showSidebarNullValues ?? false;
      }
    } catch (error) {
      console.warn('Failed to load user preferences settings from localStorage.');
    }
  }

  @action.bound
  public updateShowSidebarNullValues(showSidebarNullValues: boolean): void {
    this.showSidebarNullValues = showSidebarNullValues;
    this.saveUserPreferences();
  }

  private saveUserPreferences() {
    safeLocalStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        showSidebarNullValues: this.showSidebarNullValues,
      })
    );
  }
}

export const UserPreferencesStoreContext = createContext<UserPreferencesStore | null>(null);
UserPreferencesStoreContext.displayName = 'UserPreferencesStoreContext';
