export function omit<T extends object>(obj: T, ...fields: string[]): Partial<T> {
  const shallowCopy = {
    ...obj,
  };
  for (const field of fields) {
    // @ts-expect-error we can't make the function signature more precise like `omit<T extends object, K extends keyof T>(obj: T, ...fields: K[]): Partial<Omit<T, K>>`
    // because then we couldn't call it for objects that TS doesn't know the type of
    delete shallowCopy[field];
  }

  return shallowCopy;
}
