// cSpell:ignore langs

import allLocales from './locales';

const defaultLocale = 'en';

export interface LocaleData {
  locale: string;
}

export function loadLocaleData(
  locales: string[],
  callback: (localeData: LocaleData, isDefault: boolean) => void
): void {
  // Just use the characters before the `-` to load our locales file.
  // EX: en-US -> en
  let returnLocale = locales[0];
  let locale = returnLocale.split('-')[0].toLowerCase() as keyof typeof allLocales;
  let isDefault = false;

  // Try to fine a generated locales file in lib/langs/locales
  let loader = allLocales[locale];

  if (!loader) {
    // Try again if there are more locales to check.
    if (locales.length > 1) {
      loadLocaleData(locales.slice(1), callback);

      return;
    } else {
      // Couldn't find a match so set the default.
      isDefault = true;
      locale = returnLocale = defaultLocale;
      loader = allLocales[defaultLocale];
    }
  }

  // Dynamically load the locales file and call its `loader[LOCALE]` function.
  // It will take care of requiring all the language specific files.
  // This prevents loading a ton of language files that aren't going to be used
  // which would bloat our JS files.
  loader()(returnLocale, () => {
    callback({ locale: returnLocale }, isDefault);
  });
}

// CDEUTSCH: disable for a bit
// if (process.env.NODE_ENV !== 'production') {
// // eslint-disable-next-line
//   console.warn('Disabling [React Intl] message missing errors');

//   const oldErr = window.console.error;
//   window.console.error = (msg: any, ...args: any[]) => {
//     if (msg && typeof msg === 'string' && msg.endsWith('using default message as fallback.')) {
//       return;
//     }
//     oldErr(msg, ...args);
//   };
// }
