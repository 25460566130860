/* eslint-disable  */
import copy from 'copy-to-clipboard';
import qs, { StringifyOptions } from 'query-string-for-all';
import { queryStringAsObject } from './queryParams';

// We want to use `undefined` when modifying parsed values, so we need to extend `query-string`'s ParsedQuery interface.
// This broke thanks to this f'n guy.
// https://github.com/sindresorhus/query-string/issues/273
export interface ExtendedParsedQuery<T = string> {
  [key: string]: T | null | Array<T | null> | undefined;
}

export function parse(query: string): ExtendedParsedQuery {
  return qs.parse(query);
}

export function stringifyWithQM(object: { [key: string]: any }, options?: StringifyOptions): string {
  let result = qs.stringify(object, options);

  if (result) {
    result = `?${result}`;
  }

  return result;
}

export function queryStringParamToString(param?: string | (string | null)[] | null): string | undefined {
  if (param !== undefined) {
    if (Array.isArray(param)) {
      return param.join(' ');
    } else {
      // Should be a `string | null` now but TypeScript isn't figuring that out.
      const sParam = param as unknown as string | null;
      // Convert `null` to undefined.
      if (sParam === null) {
        return undefined;
      } else {
        return sParam;
      }
    }
  }

  return undefined;
}

export function copyLinkToQuery({ qid }: { qid?: string }) {
  const base = window.location.href.split('?')[0];
  qid ??= queryStringAsObject().qid;

  if (!qid) {
    throw new Error("Can't copy link to query without a qid");
  }

  copy(`${base}${stringifyWithQM({ qid: qid, relative: 1 })}`);
}

export function copyLinkToResult({ qid }: { qid?: string }) {
  const base = window.location.href.split('?')[0];
  qid ??= queryStringAsObject().qid;

  if (!qid) {
    throw new Error("Can't copy link to query without a qid");
  }

  copy(`${base}${stringifyWithQM({ qid: qid })}`);
}
