

module.exports = function loadercs(locale, cb) {
  require.ensure([
      'moment/locale/cs'
      , 'numbro/dist/languages/cs-CZ.min'
      
    ], (require) => {
    const moment = require('moment');
      require('moment/locale/cs');
      try { moment.locale(locale); } catch(e) { moment.locale(locale.split('-')[0].toLowerCase()); }

    const numbro = require('numbro');
      numbro.registerLanguage(require('numbro/dist/languages/cs-CZ.min'));
      numbro.setLanguage(locale);

    
    cb();
  }, 'locale-cs');
};

