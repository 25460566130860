// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".I4h194Qnpxp525aaTfxY {\n  display: block;\n  line-height: 1;\n  margin-bottom: 8px;\n}\n\n.grTCljRAussjBtFuWjwF {\n  font-size: 10px;\n  font-weight: 400;\n  text-transform: uppercase;\n  color: var(--axi-form-item-label-color);\n}\n\n.t0tZT_soJMO9QlLMpBo3 {\n  font-size: 10px;\n  font-weight: 500;\n  text-transform: uppercase;\n  color: var(--axi-form-item-label-color);\n}\n\n.J9uzonjwMswpQJ6O9k4x {\n  font-size: 10px;\n  font-weight: 500;\n  text-transform: uppercase;\n  color: var(--axi-form-item-label-color);\n  line-height: 18px;\n  margin-bottom: 10px;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "I4h194Qnpxp525aaTfxY",
	"label-default": "grTCljRAussjBtFuWjwF",
	"labelDefault": "grTCljRAussjBtFuWjwF",
	"label-strong": "t0tZT_soJMO9QlLMpBo3",
	"labelStrong": "t0tZT_soJMO9QlLMpBo3",
	"label-heading": "J9uzonjwMswpQJ6O9k4x",
	"labelHeading": "J9uzonjwMswpQJ6O9k4x"
};
export default ___CSS_LOADER_EXPORT___;
