// cSpell:ignore codegen

import { getBaseUrl } from '../../util/axios';

import { DashApi } from './codegen/Dash';
import { MonitorsApi } from './v2/codegen/Monitors';

export * from './types';

export const dashApi = new DashApi();

export const monitorsApi = new MonitorsApi({
  baseURL: getBaseUrl('/v2'),
});
