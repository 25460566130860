import moment from 'moment-timezone';

type Unit = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year';

export function selectUnit(from: Date | number, to: Date | number = Date.now()): { value: number; unit: Unit } {
  // Use moment so we can calculate `months` and `years` (since they don't have a consistent number of `days`).
  const momentFrom = moment(from);
  const momentTo = moment(to);

  if (!momentFrom.isValid() || !momentTo.isValid()) {
    throw new Error(`Invalid from or to date. ${from} to ${to}`);
  }

  const years = momentFrom.diff(momentTo, 'years');
  if (Math.abs(years) > 0) {
    return {
      // Get a more precise value to round on now that we know it's greater than 0.
      value: Math.round(momentFrom.diff(momentTo, 'years', true)),
      unit: 'year',
    };
  }

  const months = momentFrom.diff(momentTo, 'months');
  if (Math.abs(months) > 0) {
    return {
      // Get a more precise value to round on now that we know it's greater than 0.
      value: Math.round(momentFrom.diff(momentTo, 'months', true)),
      unit: 'month',
    };
  }

  const days = momentFrom.diff(momentTo, 'days');
  if (Math.abs(days) > 0) {
    return {
      // Get a more precise value to round on now that we know it's greater than 0.
      value: Math.round(momentFrom.diff(momentTo, 'days', true)),
      unit: 'day',
    };
  }

  const hours = momentFrom.diff(momentTo, 'hours');
  if (Math.abs(hours) > 0) {
    return {
      // Get a more precise value to round on now that we know it's greater than 0.
      value: Math.round(momentFrom.diff(momentTo, 'hours', true)),
      unit: 'hour',
    };
  }

  const minutes = momentFrom.diff(momentTo, 'minutes');
  if (Math.abs(minutes) > 0) {
    return {
      // Get a more precise value to round on now that we know it's greater than 0.
      value: Math.round(momentFrom.diff(momentTo, 'minutes', true)),
      unit: 'minute',
    };
  }

  const seconds = momentFrom.diff(momentTo, 'seconds');

  return {
    value: Math.round(seconds),
    unit: 'second',
  };
}
